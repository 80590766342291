import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, makeStyles } from "@material-ui/core"
import { ExpandLess, ExpandMore } from "@material-ui/icons"

const useCollapsibleContainerStyles = makeStyles(theme => (
  {
    contentWrapper: {
      width: '100%',
      '& > *:first-child': {
        marginTop: 0, // Keeps the content's top alignment in shape.
      },
    },
    default: {
      fontSize: '0.875rem', // By default markdown field would use user-agent styles, causing variation between browsers in font-size
      paddingLeft: '16px',
      borderLeft: '1px solid #ddd',
    },
    cappedHeight: {
      overflowY: 'hidden',
      position: 'relative',
      '&::after': {
        content: "''",
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '50px', /* Adjust the height of the fade effect */
        background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), white)',
        pointerEvents: 'none' /* Ensure the overlay doesn't interfere with interactions */
      }
    },
    expander: {
      width: '100%',
      textAlign: 'center'
    }
  }
))

export interface CollapsibleContainerProps {
  children: React.ReactNode
  cappedMaxHeightPx?: number
}

export function CollapsibleContainer(prop: CollapsibleContainerProps) {
  const { t } = useTranslation()
  const classes = useCollapsibleContainerStyles()
  const {cappedMaxHeightPx = 200} = prop
  const [isOverflowing, setOverflowing] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const observerRef = useRef<ResizeObserver | null>(null)

  useEffect(() => {
    const checkOverflow = () => {
      if (wrapperRef.current) {
        const hasOverflow = wrapperRef.current.scrollHeight > cappedMaxHeightPx
        setOverflowing(hasOverflow)
      }
    }

    if (observerRef.current) {
      observerRef.current.disconnect()
    }

    checkOverflow()

    if (wrapperRef.current) {
      observerRef.current = new ResizeObserver(checkOverflow)
      observerRef.current.observe(wrapperRef.current)
    }

    return () => {
      if (observerRef.current) {
      observerRef.current.disconnect()
      }
    }
  }, [cappedMaxHeightPx, prop.children])

  return (
    <div>
      <div
        style={{ maxHeight: isExpanded ? 'none' : cappedMaxHeightPx }}
        className={`${classes.default} ${isOverflowing && !isExpanded ? classes.cappedHeight : undefined}`}
      >
        <div ref={wrapperRef} className={classes.contentWrapper}>
          {prop.children}
        </div>
      </div>
      {isOverflowing && (
      <div className={classes.expander}>
        <Button onClick={() => setIsExpanded(!isExpanded)} endIcon={isExpanded ? <ExpandLess /> : <ExpandMore />} >
          {t(isExpanded ? 'genericShowLess' : 'genericShowMore')}
        </Button>
      </div>
    )}
    </div>
  )
}
