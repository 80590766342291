import { useState } from "react"
import { Backdrop, CircularProgress, makeStyles, Theme } from "@material-ui/core"
import { useTranslation } from "react-i18next"

import { useSetTitle } from "../tools/AppEvents"
import { TabbedPanel, TabbedPanelTabProp } from "./TabbedPanel"
import { SiteGroupSitesTable } from "./SiteGroupSitesTable"
import { SiteGroupSitesAdditionTable } from "./SiteGroupSitesAdditionTable"

const useStyles = makeStyles((theme: Theme) => (
  {
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    }
  }
))

export interface SiteGroupSitesViewProp {
  siteGroupId: number
}

export function SiteGroupSitesView(p: SiteGroupSitesViewProp) {
  const { t } = useTranslation()
  const classes = useStyles()
  const [inProgress] = useState(false)

  const siteGroupSitesTabs: TabbedPanelTabProp[] = [
    {
      label: t('siteGroupSitesListTabLabel'),
      child: <SiteGroupSitesTable siteGroupId={p.siteGroupId} />
    },
    {
      label: t('siteGroupSiteAdditionTabLabel'),
      child: <SiteGroupSitesAdditionTable siteGroupId={p.siteGroupId} />
    }
  ]

  useSetTitle(t('drawerMenuSites'))

  return (
    <>
    <TabbedPanel tabs={siteGroupSitesTabs} />
    <Backdrop className={classes.backdrop} open={inProgress}>
      <CircularProgress color="inherit" />
    </Backdrop>
    </>
  )
}
