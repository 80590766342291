import { useEffect } from "react"
import { downloadAttachmentWithUid } from "../tools/downloadAttachmentWithUid"
import { useSnackbar } from 'notistack'
import * as navigator from '../tools/Navigator'
import { useTranslation } from "react-i18next"
import * as snacky from './CustomSnackbarProvider'

export function SiteGroupFileLinkDownloader({path}: {path: string}) {
  
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  useEffect(() => {
    async function doDownloadFileByLink(path: string) {
      try {
        const targetId = await downloadAttachmentWithUid({ path })
        if (targetId !== null) {
          return navigator.navigateToExternalSite(`${navigator.appHomepage}/site-group/${targetId}`)
        } else {
          enqueueSnackbar(`${t('errFetchGenericNoDetails')}`, snacky.errorOpts)
        }
      } catch (err) {
        if (err instanceof Error) {
          console.error(err)
        } else {
          console.error(`${JSON.stringify(err)}`)
        }
        enqueueSnackbar(`${t('errFetchGenericNoDetails')}`, snacky.errorOpts)
      }
      return null
    }

    doDownloadFileByLink(path)
  }, [enqueueSnackbar, path, t])

  return null
}