import { useEffect, useState } from 'react'
import { Attachment, DeleteOutlined, Edit, ExpandMore, LockOutlined } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { useSnackbar } from 'notistack'
import { Accordion, AccordionSummary, AccordionDetails, Box, Fade, Grid, List, ListItem, Typography, makeStyles } from '@material-ui/core'
import { DateTime } from 'luxon'

import { MarkDownField, OverlineText, TIBox, TIButton } from './SmallComponents'
import { DetailsCard, TitleRow } from './DetailsCard'
import { Opt, getErrorMessage, undefinedLastComparator, unique, defaultTo, isDefined } from '../tools/Utils'
import { FileAttachment, FileAttachmentType } from '../tools/API'
import * as snacky from './CustomSnackbarProvider'
import * as api from '../tools/API'
import { formatAsLocalDate } from '../tools/Strings'
import { DownloadAttachmentButton } from './DownloadAttachmentButton'
import { ConfirmDialog } from './ConfirmDialog'
import { FileAttachmentCategory, splitToCategories } from '../tools/FileAttachmentCategory'
import { BatchOperation, BatchOperationDialog, BatchOperationError } from './BatchOperationDialog'
import { sortFilesByDescription } from '../tools/FileSorter'
import { AddAttachmentDialog } from './AddAttachmentDialog'

export interface FilesCardProp {
  cardId: string
  title: string
  files: FileAttachment[]

  /** If true then only list active files. Defaults to true. */
  onlyActive?: boolean

  fileSaver: (file: File, description: Opt<string>, category: Opt<string>) => Promise<FileAttachment>
  fileUpdater: (fileId: number, description: Opt<string>, category: Opt<string>) => Promise<FileAttachment>

  /** If defined, allows replacing files. */
  fileReplacer?: (fileId: number, file: File, description: Opt<string>, category: Opt<string>) => Promise<FileAttachment>

  /** Defaults to false. */
  embedded?: boolean

  /** Defaults to false. */
  grouped?: boolean

  /** Defaults to false. */
  disabled?: boolean

  /** Id of the parent entity the files belong to. If not defined, generating file link is not allowed. */
  targetId?: number

  /** Type of added files. */
  newFileType: FileAttachmentType

  /** If defined, allow editing of only this type of files. */
  editableType?: FileAttachmentType
}

export function FilesCard(p: FilesCardProp) {
  const { t } = useTranslation()
  const [addDialogOpen, setAddDialogOpen] = useState(false)
  const [fileCount, setFileCount] = useState(p.files.length)

  const sortedFiles = sortFilesByDescription(p.files);

  const body = <FilesCardBody
    addDialogOpen={addDialogOpen}
    onAddDialogClosed={() => setAddDialogOpen(false)}
    onFileCountChanged={setFileCount}

    files={sortedFiles}
    onlyActive={p.onlyActive ?? true}
    fileSaver={p.fileSaver}
    fileUpdater={p.fileUpdater}
    fileReplacer={p.fileReplacer}
    grouped={p.grouped ?? false}
    noPadContent={p.embedded}
    disabled={p.disabled ?? false}
    targetId={p.targetId}
    newFileType={p.newFileType}
    editableType={p.editableType}
  />

  if (p.embedded ?? false) {
    return (
      <Box display='flex' flexDirection='column'>
        <TitleRow
          title={p.title}
          onAddClicked={() => setAddDialogOpen(true)}
          addTooltipKey='fileAddTooltip'
          addButtonEdge='end'
          disabled={p.disabled ?? false}
        />
        {body}
      </Box>
    )

  } else {
    return (
      <DetailsCard
        disabled={p.disabled ?? false}
        cardId={p.cardId}
        title={p.title}
        subtitle={t('filesCardSubtitle', {count: fileCount})}
        icon={<Attachment/>}
        onAddClicked={() => setAddDialogOpen(true)}
        addTooltipKey='buttonAddFileTooltip'
        noPadContent={true}
      >
        {body}
      </DetailsCard>
    )
  }
}

const useStyles = makeStyles(theme => (
  {
    groupAccordion: {
      '&:before': { // Hide the separator line that is only shown between AccordionDetails' children (not on top of the first one).
        display: 'none'
      },
      borderTop: '1px solid #ddd',
      '&:last-child': { // Add margin at the bottom (of a collapsed accordion) to allow rounded corners of the card show correctly.
        marginBottom: '5px'
      },
      '&.MuiAccordion-root.Mui-expanded:last-child': { // Awkward, but was needed to override the square cornered defaults for expanded accordion.
        marginBottom: '5px'
      },
      '&.Mui-expanded': { // Remove default expansion effect of neighboring accordions when one is expanded
        margin: 0
      }
    },
    groupAccordionSummary: {
      backgroundColor: '#F5F5F5'
    },
    groupAccordionDetails: {
      paddingTop: 0,
      paddingBottom: 0
    },
    ungroupedGrid: {
      paddingBottom: '16px'
    },
    padSides: {
      paddingLeft: '16px',
      paddingRight: '16px'
    }
  }
))

interface FilesCardBodyProp {
  files: FileAttachment[]
  onlyActive: boolean
  fileSaver: (file: File, description: Opt<string>, category: Opt<string>) => Promise<FileAttachment>
  fileUpdater: (fileId: number, description: Opt<string>, category: Opt<string>) => Promise<FileAttachment>
  fileReplacer?: (fileId: number, file: File, description: Opt<string>, category: Opt<string>) => Promise<FileAttachment>
  grouped: boolean
  disabled?: boolean
  noPadContent?: boolean

  addDialogOpen: boolean
  onAddDialogClosed: () => void
  onFileCountChanged: (count: number) => void

  targetId?: number
  newFileType: FileAttachmentType
  editableType?: FileAttachmentType
}

function FilesCardBody(p: FilesCardBodyProp) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [files, setFiles] = useState<FileAttachment[]>([])
  const [fileGroups, setFileGroups] = useState<FileAttachmentCategory[]>([])
  const [saving, setSaving] = useState(false)
  const [confirmDeleteFile, setConfirmDeleteFile] = useState<Opt<FileAttachment>>(undefined)
  const [deletingFileId, setDeletingFileId] = useState<Opt<number>>(undefined)
  const [editingFile, setEditingFile] = useState<Opt<FileAttachment>>(undefined)
  const [categoryOptions, setCategoryOptions] = useState<string[]>([])
  const [batchOperations, setBatchOperations] = useState<BatchOperation<FileAttachment>[]>([])

  useEffect(() => {
    p.onFileCountChanged(files.length)
    p.grouped && setFileGroups(splitToCategories(files).sort((catA, catB) => undefinedLastComparator(catA.name, catB.name)))
    setCategoryOptions(unique(files.map(file => file.category).filter(category => !!category) as string[]))
  }, [files, p])

  useEffect(() => {
    const relevantFiles = p.files.filter(f => !p.onlyActive || f.isActive)
    setFiles(relevantFiles)
  }, [p.files, p.onlyActive, p.grouped])

  async function handleSaveFile(file: Opt<File>, description: Opt<string>, category: Opt<string>) {
    try {
      setSaving(true)

      if (editingFile) {
        if (isDefined(file) && isDefined(p.fileReplacer)) {
          // Replace file with the update
          const replaced = await p.fileReplacer(editingFile.id, file, description, category)
          setFiles(old => old.map(f => f.id === editingFile.id ? replaced : f))
        } else {
          // Update metadata
          const updated = await p.fileUpdater(editingFile.id, description, category)
          setFiles(old => old.map(f => f.id === editingFile.id ? updated : f))
        }

      } else {
        const attachment = await p.fileSaver(file!, description, category)
        setFiles(old => [...old, attachment])
      }

      p.onAddDialogClosed()
    } catch (err:any) {
      console.log(`Error saving file: ${JSON.stringify(err)}`)
     if (err.data?.status === 'insecure_file') {
        enqueueSnackbar(`${t('errSaveDueToScanFailure')}: ${getErrorMessage(err)}`, snacky.errorOpts)
      } else {
        enqueueSnackbar(t('errSave', {message: getErrorMessage(err)}), snacky.errorOpts)
      }
    } finally {
      setSaving(false)
      setEditingFile(undefined)
    }
  }

  async function handleSaveFiles(files: File[]) {
      setSaving(true)
      setBatchOperations( () =>
        files.map(file => ({
          operation: () => p.fileSaver(file!, undefined, undefined),
          identifier: file.name,
          onSuccess: (attachment) => setFiles(old => [...old, attachment]),
          onError: (error) => console.log(`Error saving file in batch: ${JSON.stringify(error)}`)
        }))
      )
  }

  async function handleDelete(file: FileAttachment) {
    try {
      setDeletingFileId(file.id)
      await api.deleteFileAttachment(file.id, false)
      setFiles(old => old.filter(f => f.id !== file.id))
    } catch (err) {
      console.log(`Error deleting file: ${JSON.stringify(err)}`)
      enqueueSnackbar(t('errSave', {message: getErrorMessage(err)}), snacky.errorOpts)
    } finally {
      setDeletingFileId(undefined)
    }
  }

  const onBatchComplete = () => {
    p.onAddDialogClosed()
    setBatchOperations([])
    setSaving(false)
  }

  return (
    <Grid container direction='column' className={p.grouped || p.noPadContent ? undefined : classes.padSides}>
      {
        p.grouped ?
          fileGroups.map((group, index) => (
              <FileCategory
                key={`category-${index}`}
                category={group}
                deletingFileId={deletingFileId}
                editableType={p.editableType}
                onDeleteClicked={setConfirmDeleteFile}
                onEditClicked={setEditingFile}
                />
            ))
          : files.map((file, index) => (
            <FileItem
              key={`fileitem-${index}`}
              file={file}
              editableType={p.editableType}
              disabled={deletingFileId === file.id}
              onDeleteClicked={setConfirmDeleteFile}
              onEditClicked={setEditingFile}
            />
          ))
      }
      { (p.addDialogOpen || editingFile !== undefined) &&
        <AddAttachmentDialog
          saving={saving}
          showCategory={p.grouped}
          file={editingFile}
          categoryOptions={categoryOptions}
          onCancel={() => {
            setEditingFile(undefined)
            p.onAddDialogClosed()
          }}
          onSave={(file, description, category) => handleSaveFile(file, description, category)}
          onSaveMany={files => handleSaveFiles(files)}
          targetId={p.targetId}
          fileType={p.newFileType}
          allowReplace={isDefined(p.fileReplacer)}
        />
      }
      { confirmDeleteFile &&
        <ConfirmDialog
          open={true}
          title={t('confirmFileRemoveTitle')}
          text={t('confirmFileRemoveText', {name: confirmDeleteFile.description ?? confirmDeleteFile.filename})}
          onAction={(yes) => {
            const file = confirmDeleteFile!
            setConfirmDeleteFile(undefined)

            if (yes === true) {
              handleDelete(file)
            }
          }}
        />
      }
      { saving && batchOperations.length > 0 &&
        <BatchOperationDialog
          operations={batchOperations}
          onCompletion={onBatchComplete}
          titleKey="fileUploadBatchDialogTitle"
          inProgressKey="fileUploadBatchDialogProgress"
          successKey="fileUploadBatchSuccess"
          failedKey="fileUploadBatchFailed"
          failureEntriesRenderer={renderUploadFailureEntries}
        />
      }
    </Grid>
  )
}

function renderUploadFailureEntries(t: TFunction, failed: BatchOperationError[]) {
  const filesWithError = failed.map(f => (
    {
      filename: f.identifier as string,
      errormessage: api.getPrettyErrorMessage(f.error, t)
    }
  ))
  const filesByError = new Map<string, string[]>()
  filesWithError.forEach(f => {
    return filesByError.set(
      f.errormessage,
      defaultTo([] as string[], filesByError.get(f.errormessage)).concat([f.filename])
    )
  })

  const listItems = Array.from(filesByError.keys()).map(errormessage => {
    return <ListItem key={errormessage} disableGutters={true} style={{paddingTop: 0,paddingBottom: 0}}>
      <Box display="flex" flexDirection="column">
        <Typography variant="subtitle1">{`${errormessage}:`}</Typography>
        <List disablePadding={true}>
          {filesByError.get(errormessage)!.map(filename => (
            <ListItem key={filename} style={{paddingTop: 0}}>
              <Typography variant="body1" color="textSecondary">{filename}</Typography>
            </ListItem>
          ))}
        </List>
      </Box>
    </ListItem>
  })

  return <List dense={true} disablePadding={true}>{listItems}</List>
}

interface FileCategoryProp {
  category: FileAttachmentCategory
  deletingFileId: Opt<number>
  editableType?: FileAttachmentType
  onDeleteClicked: (file: FileAttachment) => void
  onEditClicked: (file: FileAttachment) => void
}

function FileCategory(prop: FileCategoryProp) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)

  return <Accordion
    elevation={0} // Otherwise the Accordion would look like a card on top of a card.
    className={classes.groupAccordion}
    square={true}
    expanded={expanded}
    onChange={(_event, expanded) => setExpanded(expanded)}
  >
    <AccordionSummary
      expandIcon={<ExpandMore/>}
      className={classes.groupAccordionSummary}
    >
      <Typography variant="subtitle2">{prop.category.name ?? t('fileUncategorised')}</Typography>
    </AccordionSummary>
    <AccordionDetails className={classes.groupAccordionDetails}>
      <Grid container direction='column' spacing={1}>
        {prop.category.files.map(f => (
              <FileItem
                key={f.id}
                file={f}
                editableType={prop.editableType}
                disabled={prop.deletingFileId === f.id}
                onDeleteClicked={prop.onDeleteClicked}
                onEditClicked={prop.onEditClicked}
              />
            ))}
      </Grid>
    </AccordionDetails>
  </Accordion>
}

interface FileItemProp {
  file: FileAttachment
  disabled: boolean
  editableType?: FileAttachmentType
  onDeleteClicked: (file: FileAttachment) => void
  onEditClicked: (file: FileAttachment) => void
}

function FileItem(prop: FileItemProp) {
  const {file, editableType } = prop
  const [showButtons, setShowButtons] = useState(false)
  const isEditingAllowed = isFileEditingAllowed(file.type, editableType)

  return (
    <Grid item
      onMouseEnter={(_event) => setShowButtons(true)}
      onMouseLeave={(_event) => setShowButtons(false)}
    >
      <Box display='flex' alignItems='center'>
        <Box display='flex' flexGrow={1} flexDirection='column' flexWrap='nowrap' py={1}>
          <OverlineText>{formatAsLocalDate(DateTime.fromISO(file.added))}</OverlineText>
          <Typography variant='subtitle1' >{file.filename}</Typography>
        </Box>
        <Fade in={showButtons}>
          <Box display='flex' flexDirection='row'>
            <DownloadAttachmentButton file={file} />
            {isEditingAllowed && <TIButton
              disabled={prop.disabled}
              onClick={() => prop.onEditClicked(file)}
              icon={<Edit/>}
              tooltipKey='fileEditTooltip'
            />}
            {isEditingAllowed && <TIButton
              edge='end'
              disabled={prop.disabled}
              onClick={() => prop.onDeleteClicked(file)}
              icon={<DeleteOutlined/>}
              tooltipKey='fileDeleteTooltip'
            />}
          </Box>
        </Fade>
        {!isEditingAllowed && <Box display='flex' flexDirection='row'>
          <TIBox tooltipKey='fileLockedTooltip' icon={<LockOutlined/>} edge='end' />
        </Box> }
      </Box>
      <Box display='flex' alignItems='center'>
        <Box display='flex' flexGrow={1} flexDirection='column' flexWrap='nowrap'>
          { file.description && <MarkDownField md={file.description}/> }
        </Box>
      </Box>
    </Grid>
  )
}

function isFileEditingAllowed(fileType?: FileAttachmentType, editableType?: FileAttachmentType) {
  if (isDefined(editableType)) {
    return editableType === fileType
  }
  return true
}
