import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

import * as api from '../tools/API'
import * as snacky from './CustomSnackbarProvider'
import { getErrorMessage } from '../tools/Utils'
import * as navigator from '../tools/Navigator'
import { BaseTable } from './BaseTable'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core'
import { SecondaryText, TextItem } from './SmallComponents'

const useStyles = makeStyles(theme => (
  {
    createButton: {
      marginBottom: '1rem'
    }
  }
))

export interface SiteGroupsTableProp {
  customerId?: number
}

export function SiteGroupsTable(p: SiteGroupsTableProp) {
  const { t } = useTranslation()
  const classes = useStyles()
  const [ isCreating, setIsCreating ] = useState(false)
  const columns = [
    { width: 100, field: 'name', headerTextId: 'siteGroupsHeaderName', sortBy: 'NAME' },
  ]

  const onDialogClose = () => {
    setIsCreating(false)
  }

  const onCreate = async (id: number) => {
    navigator.push(`/site-group/${id}`)
    setIsCreating(false)
  }

  return <>
    <Button
      onClick={() => setIsCreating(true)}
      disabled={false}
      className={classes.createButton}
      variant='contained'
      color='primary'
    >
      {t('newSiteGroupButtonLabel')}
    </Button>
    <BaseTable
      tableId="siteGroupTable"
      columns={columns}
      filterLabelId='siteGroupsSearchLabel'
      onOpenDetails={group => navigator.push(`/site-group/${group.id}`, {group: group})}
      onFetchItems={api.searchSiteGroups}
    />
    { isCreating &&
      <AddSiteGroupDialog isOpen={isCreating} onClose={onDialogClose} onAdd={onCreate}/>
    }
  </>
}

interface AddSiteGroupDialogProp {
  isOpen: boolean,
  onClose: () => void
  onAdd: (id: number) => void
}

function AddSiteGroupDialog(p: AddSiteGroupDialogProp) {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [groupName, setGroupName] = useState('')

  const onClose = (cb: () => void) => {
    cb()

    // Clear fields in case dialog gets opened again.
    setGroupName('')
  }

  const onCancel = () => {
    onClose(p.onClose)
  }

  const handleCreate = async (name: string) => {
    try {
      const group = await api.createSiteGroup({name: name})
      onClose(() => p.onAdd(group.id!))
    } catch (err) {
      console.log(`Failed to create site group: ${JSON.stringify(err)}`)
      enqueueSnackbar(t('errSave', {message: getErrorMessage(err)}), snacky.errorOpts)
    }
  }

  return (
    <Dialog
      open={p.isOpen}
      onClose={onCancel}
      fullWidth={true}
      maxWidth='xs'
    >
      <DialogTitle>{t('addSiteGroupDialogTitle')}</DialogTitle>
      <DialogContent>
        <Grid container direction='column' spacing={2}>
          <Grid item>
            <SecondaryText>{t('addSiteGroupDialogHelpText')}</SecondaryText>
          </Grid>
          <Grid item>
            <TextItem
              name='name'
              label={t('newSiteGroupNameLabel')}
              value={groupName}
              required={true}
              onChange={event => setGroupName(event.target.value)}
              autoFocus={true}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color='primary'>{t('buttonCancel')}</Button>
        <Button onClick={() => handleCreate(groupName)} color='primary'>{t('buttonAdd')}</Button>
      </DialogActions>
    </Dialog>
  )
}
