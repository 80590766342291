import { Backdrop, CircularProgress, makeStyles, Theme } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useSetTitle } from "../tools/AppEvents"
import { TabbedPanel, TabbedPanelTabProp } from "./TabbedPanel"
import { SitesTable } from "./SitesTable"
import { useState } from "react"
import { SiteGroupsTable } from "./SiteGroupsTable"

const useStyles = makeStyles((theme: Theme) => (
  {
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    }
  }
))

export interface SitesViewProp {
  customerId?: number
}

export function SitesView(p: SitesViewProp) {
  const { t } = useTranslation()
  const classes = useStyles()
  const [inProgress] = useState(false)

  const sitesTabs: TabbedPanelTabProp[] = [
    {
      label: t('siteListTabLabel'),
      child: <SitesTable customerId={p.customerId} />
    },
    {
      label: t('siteGroupsListTabLabel'),
      child: <SiteGroupsTable customerId={p.customerId} />
    }
  ]

  useSetTitle(t('drawerMenuSites'))

  return (
    <>
    <TabbedPanel tabs={sitesTabs} />
    <Backdrop className={classes.backdrop} open={inProgress}>
      <CircularProgress color="inherit" />
    </Backdrop>
    </>
  )
}
