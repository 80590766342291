import { useEffect } from 'react'
import { useEventListener } from './Hooks'

export type AppEventType = "DetailsExpandAll" | "DetailsCollapseAll" | "UpdateTitle" | "ExportToExcel" | "RefreshResults"
// Using ^ instead of an enum because couldn't reverse map from string to Enum value.

export function dispatchAppEvent(type: AppEventType, data?: any) {
  const appEvent = new CustomEvent(type, { detail: data })
  document.dispatchEvent(appEvent)
}

export function useAppEventListener(type: AppEventType, handler: (type: AppEventType, data?: any) => void) {
  useEventListener(type, event => {
    const ce = event as CustomEvent
    handler(event.type as AppEventType, ce.detail)
  })
}

export function useSetTitle(title: string) {
  useEffect(() => {
    dispatchAppEvent('UpdateTitle', { title: title })
  }, [title])
}
