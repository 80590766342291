import history from 'history/browser'
import { isDefined, isDevEnv, isEmpty, isLocalEnv, isStagingEnv, Opt } from './Utils'
import { useMemo } from 'react'
import { Customer } from './API'

export const appHomepage = process.env.REACT_APP_HOMEPAGE ?? '/'
const appPath = (appHomepage).replace(/^https?:\/\//, '')

export const PATH_SIGN_IN = '/sign-in'
export const PATH_SIGNED_OUT = '/sign-out'
export const PATH_SITES = '/sites'
export const PATH_CUSTOMERS = '/customers'
export const PATH_CONTRACTLINES = '/contract-lines'
export const PATH_SITE_FILELINK = '/site/file'
export const PATH_SITEGROUP_FILELINK = '/site-group/file'

interface NextState {
  next?: string
}

type NaviState = NextState | undefined | any

export function navigateToExternalSite(url: string, state?: NaviState) {
  window.location.assign(url)
}

export function push(path: string, state?: NaviState) {
  history.push(`${appPath}${path}`, state)
}

export function replace(path: string, state?: NaviState) {
  history.replace(`${appPath}${path}`, state)
}

export function replacePartially(pattern: string | RegExp, replacement: string) {
  const newPath = getRelativePath().replace(pattern, replacement)
  history.replace(`${appPath}${newPath}`, history.location.state)
}

export function replaceWithNextOr(path: string, state?: NaviState) {
  const next = getNext()

  if (next) {
    replace(next, state)
  } else {
    replace(path, state)
  }
}

export function getTabIndex() {
  const matches = history.location.pathname.match('/tab-([0-9]+)')
  if (isDefined(matches) && !isEmpty(matches)) {
    return parseInt(matches[matches.length-1], 10)
  }
  return undefined
}

export function getNext(): Opt<string> {
  const state = history.location.state as Opt<NaviState>
  return state?.next
}

export function getRelativePath() {
  return toRelativePath(history.location.pathname)
}

export function toRelativePath(absolutePath: string) {
  const root = appPath

  if (absolutePath.indexOf(root) === 0) {
    return absolutePath.substring(root.length)
  } else {
    return absolutePath
  }
}

export function back() {
  history.back()
}

export function refresh() {
  history.go(0)
}

export function getCurrentState() {
  return history.location.state
}

let historyUnlisteners: Map<String, Function> = new Map()

export const useListenPath = (key: string, callback: (path: string) => void) => {
  // useMemo instead of useEffect as we need to be listening before rendering.
  // Otherwise navigator.replace() calls might get ignored and state would
  // not get updated correctly.
  // https://stackoverflow.com/a/56818036
  useMemo(() => {
    // Seems that 2 history listeners can get registered if one doesn't unlisten
    // - even though the "Start listen.." log message doesn't get printed twice.
    // Storing the unlistenHistory callback this way seems a bit unorthodox, but
    // it seems to do the trick (kind of, with side-effects...).
    let unlistener = historyUnlisteners.get(key)

    if (unlistener) {
      unlistener()
    }

    console.log(`Start listening to history`)

    historyUnlisteners.set(key, history.listen((event) => {
      const newPath = toRelativePath(event.location.pathname)
      callback(newPath)
    }))

    return 0
  }, [key, callback])
}

export function openCustomerDetails(customer: Customer) {
  push(`/customer/${customer.id}`, customer)
}

export function downloadFileWithLink(fileUid: string) {
  push(`/${fileUid}`)
}

export const laadunseurantaBaseUrlPrd = 'https://laadunseuranta.iss.fi'
export const laadunseurantaBaseUrlStaging = 'https://laadunseuranta-staging.iss.fi'
export const laadunseurantaBaseUrlDev = 'https://laadunseuranta-dev.iss.fi'
export const laadunseurantaBaseUrlLocal = 'http://localhost:3000'

/**
 * @param path must start with a forward slash.
 */
 export function getLaadunseurantaLink(path: string) {
  let baseUrl = laadunseurantaBaseUrlPrd;
  if (isStagingEnv) {
    baseUrl = laadunseurantaBaseUrlStaging;
  } else if (isDevEnv) {
    baseUrl = laadunseurantaBaseUrlDev;
  } else if (isLocalEnv) {
    baseUrl = laadunseurantaBaseUrlLocal;
  }
  return `${baseUrl}${path}`
}

export const kohdekansioBaseUrlLocal = 'http://localhost:3002'

export function getKohdekansioLink(path: string) {
  let baseUrl

  if (isLocalEnv) {
    baseUrl = `${kohdekansioBaseUrlLocal}${appHomepage}`
  } else {
    baseUrl = getLaadunseurantaLink(appHomepage)
  }
  return `${baseUrl}${path}`
}
