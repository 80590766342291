import { useEffect, useState } from 'react'
import { Button, useMediaQuery, useTheme, Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { VisibilityOutlined, VisibilityOff } from '@material-ui/icons'
import { TFunction, useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

import { getSiteGroupById, listSiteGroupSites, removeSiteFromGroup, SiteGroup } from '../tools/API'
import { BaseTable, ColumnDef } from './BaseTable'
import { dispatchAppEvent, useSetTitle } from '../tools/AppEvents'
import { Site, PageResult } from '../tools/API'
import * as navigator from '../tools/Navigator'
import { getErrorMessage } from '../tools/Utils'
import * as snacky from './CustomSnackbarProvider'
import { TIBox } from './SmallComponents'
import { ConfirmDialog } from './ConfirmDialog'

const columnsWide: ColumnDef[] = [
  { width: 25, field: 'name', headerTextId: 'sitesHeaderName', sortBy: 'NAME' },
  { width: 16, field: 'streetAddress', headerTextId: 'sitesHeaderStreet', sortBy: 'STREET_ADDRESS' },
  { width: 9, field: 'postCode', headerTextId: 'sitesHeaderZip', sortBy: 'POST_CODE' },
  { width: 12, field: 'city', headerTextId: 'sitesHeaderCity', sortBy: 'CITY' },
  { width: 10, field: 'isPublished', headerTextId: 'sitesHeaderPublished', sortBy: 'IS_PUBLISHED'},
]

const columnsNarrow: ColumnDef[] = [
  { width: 45, field: 'name', headerTextId: 'sitesHeaderName', sortBy: 'NAME' },
  { width: 16, field: 'streetAddress', headerTextId: 'sitesHeaderStreet', sortBy: 'STREET_ADDRESS' },
  { width: 12, field: 'isPublished', headerTextId: 'sitesHeaderPublished', sortBy: 'IS_PUBLISHED'},
]

const visibleMarker = <TIBox tooltipKey={'sitesTableVisible'} icon={<VisibilityOutlined/>}/>
const notVisibleMarker = <TIBox tooltipKey={'sitesTableNotVisible'} icon={<VisibilityOff/>}/>

function mapValue(t: TFunction<"translation">, field: string, value: any) {
  if (field === 'isPublished') {
    return value === true ? visibleMarker : notVisibleMarker;
  } else {
    return value
  }
}

class ConstructablePageResult<T> implements PageResult<T> {
  results: T[]
  page: number
  totalResults: number
  lastMatch?: (number|string)[]
  constructor(results: T[], page: number, totalResults: number, lastMatch?: (number|string)[]) {
    this.results = results
    this.page = page
    this.totalResults = totalResults
    this.lastMatch = lastMatch
  }
}

export interface SiteGroupSitesTableProp{
  siteGroupId: number
}

interface SiteGroupSitesState {
  group?: SiteGroup
}

export function SiteGroupSitesTable(p: SiteGroupSitesTableProp) {
  const { t } = useTranslation()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const columns = matches ? columnsWide : columnsNarrow
  const tableId = `siteGroup/sites-${p.siteGroupId}`
  const [groupName, setGroupName] = useState(((navigator.getCurrentState() ?? {}) as SiteGroupSitesState).group?.name)
  const [actionInProgress, setActionInProgress] = useState(false)
  const [copyFiles, setCopyFiles] = useState(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [sitesToRemove, setSitesToRemove] = useState<Site[]>([])
  const { enqueueSnackbar } = useSnackbar()

  async function loadGroup() {
    try {
      let group = await getSiteGroupById(p.siteGroupId)
      setGroupName(group.name)
    } catch (err) {
      console.log(`Error fetching site group: ${JSON.stringify(err)}`)
      enqueueSnackbar(t('errFetchSiteGroup', {message: getErrorMessage(err)}), snacky.errorOpts)
    }
  }

  async function confirmRemoveSites() {
    setActionInProgress(true)
    Promise.all(sitesToRemove.map(site => removeSite(site)))
      .then(response => {
        enqueueSnackbar((t('siteGroupSitesRemovedInfo')), snacky.infoOpts)
        dispatchAppEvent('RefreshResults')
      })
      .finally(() => {
        setActionInProgress(false)
        setShowConfirmDialog(false)
        setSitesToRemove([])
      })
  }

  async function removeSite(site: Site) {
    try {
      await removeSiteFromGroup(p.siteGroupId, site.id, copyFiles)
    } catch (err) {
      console.log(`Error removing site from group: ${JSON.stringify(err)}`)
      enqueueSnackbar(t('errRemoveSiteFromGroup', {message: getErrorMessage(err)}), snacky.errorOpts)
    }
  }

  useEffect(() => {
    if (groupName === undefined) {
      loadGroup()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupName])

  useSetTitle(groupName ? t('siteGroupSitesTitleWithName', {name: groupName}) : t('siteGroupSitesTitle'))

  const copyFilesOption = (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={copyFiles}
            onChange={(e) => setCopyFiles(e.target.checked)}
            color="secondary"
          />
        }
        label={
          <Typography variant="subtitle1" color="textSecondary">
            {t(sitesToRemove.length > 1 ? 'siteInfoCopyFilesLabelMultiple' : 'siteInfoCopyFilesLabel')}
          </Typography>
        }
      />
      <Typography 
        variant="body2" 
        color="textSecondary" 
        style={{ marginLeft: 30}}
      >
        {t(sitesToRemove.length > 1 ? 
            'siteInfoCopyFilesHelpMultiple' : 
            'siteInfoCopyFilesHelp')}
      </Typography>
    </Box>
  );

  return (
    <>
      <BaseTable
        key={tableId} // Without the key table searchArgs aren't loaded from storage (initialized) when one navigates from customer specific sites to all sites.
        tableId={tableId}
        columns={columns}
        onOpenDetails={(newSite) => {
          navigator.push(`/site/${newSite!.id}`, {site: newSite})
        }}
        onFetchItems={args => listSiteGroupSites(p.siteGroupId).then(results => {
          return new ConstructablePageResult<Site>(results, 0, results.length)
        })}
        valueMapper={(field, value) => mapValue(t, field, value)}
        overrideArgs={{page: 0}}
        selectable={true}
        selectionAction={(selectedItems: Site[]) => {
          return <SiteRemoveButton 
            onClick={() => {
              setSitesToRemove(selectedItems);
              setShowConfirmDialog(true);
            }} 
            disabled={selectedItems.length === 0} 
            inProgress={actionInProgress} 
          />
        }}
      />
      {showConfirmDialog && (
        <ConfirmDialog
          open={showConfirmDialog}
          disabled={actionInProgress}
          title={t(sitesToRemove.length > 1 ? 
            'siteInfoConfirmRemoveMultipleFromGroupTitle' : 
            'siteInfoConfirmRemoveFromGroupTitle')}
          text={t(sitesToRemove.length > 1 ? 
            'siteInfoConfirmRemoveMultipleFromGroupText' : 
            'siteInfoConfirmRemoveFromGroupText', 
            {name: groupName, count: sitesToRemove.length})}
          customContent={copyFilesOption}
          onAction={async (yes) => {
            if (yes) {
              await confirmRemoveSites();
            } else {
              setShowConfirmDialog(false);
            }
          }}
        />
      )}
    </>
  )
}

interface SiteRemoveButtonProp {
  onClick: (value: any) => void
  disabled: boolean
  inProgress: boolean
}

function SiteRemoveButton(p: SiteRemoveButtonProp) {
  const { t } = useTranslation()

  return (
    <Button
      color="primary"
      disabled={p.disabled || p.inProgress}
      onClick={p.onClick}
    >{t('siteGroupSiteRemoveButtonLabel')}</Button>
  )
}
