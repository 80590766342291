import { useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, makeStyles, Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid } from '@material-ui/core'
import { DescriptionOutlined, ExpandMore, DeleteOutlined, Edit } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

import { DetailsCard } from './DetailsCard'
import { FilesCard } from './FilesCard'
import { ConfirmDialog } from './ConfirmDialog'
import { MarkDownField, MarkdownInstructions, TextItem, TIButton } from './SmallComponents'
import { ProductGroupSelectorItem } from './ProductGroupSelectorItem'
import { Opt, blankAsUndefined, getErrorMessage, isDefined, subtitleHandler } from '../tools/Utils'
import * as api from '../tools/API'
import * as snacky from './CustomSnackbarProvider'
import { ServiceDescription, ServiceDescriptionBase, ServiceDescriptionBrief, ProductGroup } from '../tools/API'
import i18n from 'i18next'

export interface ServiceDescriptionListCardProp {
  siteId: number
}

function compareServiceDescriptions(a: ServiceDescription, b: ServiceDescription) {
  const dCode = a.serviceCode.localeCompare(b.serviceCode, i18n.language)
  if (dCode !== 0) {
    return dCode
  }
  return a.name.localeCompare(b.name, i18n.language)
}

/**
 * Element displaying all service descriptions of the specified site.
 */
export function ServiceDescriptionListCard(p: ServiceDescriptionListCardProp) {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [inProgress, setInProgress] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [descriptions, setDescriptions] = useState<ServiceDescriptionBrief[]>([])
  const [adding, setAdding] = useState(false)
  const [saving, setSaving] = useState(false)

  function handleChanged(newDescriptions: ServiceDescriptionBrief[]) {
    setInProgress(true)
    newDescriptions.sort(compareServiceDescriptions)
    setDescriptions(newDescriptions)
    setInProgress(false)
  }

  async function fetchServiceDescriptionsBySiteId() {
    try{
      setInProgress(true)
      const descriptionsResp:ServiceDescriptionBrief[] = await api.getServiceDescriptionsBySiteId(p.siteId, true)
      handleChanged(descriptionsResp)
      setInProgress(false)
    } catch (err) {
      console.log(`Error fetching: ${JSON.stringify(err)}`)
      enqueueSnackbar(t('errFetchGeneric', {message: getErrorMessage(err)}), snacky.errorOpts)
      setHasError(true)
      setInProgress(false)
    }
  }

  async function handleAdd(sd: ServiceDescriptionBase, template?: ServiceDescriptionBrief) {
    try {
      setSaving(true)
      let created = await api.createServiceDescription(p.siteId, sd)

      if (template) {
        const index = descriptions.findIndex(d => d.isTemplate && d.serviceCode === template.serviceCode)

        if (index !== -1) {
          descriptions[index] = created
          setDescriptions(descriptions)
        } else {
          console.warn(`could not find template with code ${template.serviceCode}`)
          setDescriptions([...descriptions, created])
        }

      } else {
        setDescriptions([...descriptions, created])
      }

    } catch (err) {
      console.log(`Error adding service description: ${JSON.stringify(err)}`)
      enqueueSnackbar(t('errSave', {message: getErrorMessage(err)}), snacky.errorOpts)
    } finally {
      setAdding(false)
      setSaving(false)
    }
  }

  return (
    <DetailsCard
      cardId='serviceDescriptions'
      title={t('siteServiceDescriptionsTitle')}
      subtitle={
        subtitleHandler({
          inProgress,
          hasError, 
          loadingText:t('siteServiceDescriptionLoading'),
          subtitleText:t('siteServiceDescriptionsSubtitle', {count: descriptions.length}),
          errorMsg:t('errFetchGenericNoDetails')
        })
      }
      icon={<DescriptionOutlined/>}
      noPadContent={true}
      onAddClicked={() => setAdding(true)}
      addTooltipKey='sdcAddTooltip'
      onInit={isDefined(p.siteId) ? fetchServiceDescriptionsBySiteId : undefined}
    >
      <Box display='flex' flexDirection='column' flexGrow={1}>
        {descriptions.map(sd =>
          <ServiceDescriptionCard
            key={sd.isTemplate ? `template-${sd.serviceCode}` : sd.id}
            sd={sd}
            onDeleted={deleted => setDescriptions(descriptions.filter(sd => sd.id !== deleted.id))}
            // Deleting marks a SD as inactive (it remains in DB) and thus a deleted SD won't reappear as a template
            // even after reloading the list of SDs.
            onSaveTemplate={(template, changes) => handleAdd(changes, template)}
          />)
        }
        { adding &&
        <DescriptionDialog
          disabled={saving}
          onCancel={() => setAdding(false)}
          onSave={handleAdd}
        />
        }
      </Box>
    </DetailsCard>
  )
}

const useStyles = makeStyles(theme => (
  {
    accordion: {
      '&:before': { // Hide the separator line that is only shown between AccordionDetails' children (not on top of the first one).
        display: 'none'
      },
      borderTop: '1px solid #ddd',
      '&:last-child': { // Add margin at the bottom (of a collapsed accordion) to allow rounded corners of the card show correctly.
        marginBottom: '5px'
      },
      '&.MuiAccordion-root.Mui-expanded:last-child': { // Awkward, but was needed to override the square cornered defaults for expanded accordion.
        marginBottom: '5px'
      },
      '&.Mui-expanded': { // Remove default expansion effect of neighboring accordions when one is expanded
        margin: 0
      }
    }
  }
))

export interface ServiceDescriptionCardProp {
  sd: ServiceDescriptionBrief
  onDeleted: (sd: ServiceDescriptionBrief) => void
  onSaveTemplate: (template: ServiceDescriptionBrief, changes: ServiceDescriptionBase) => Promise<void>
}

export function ServiceDescriptionCard(p: ServiceDescriptionCardProp) {
  const { t } = useTranslation()
  const [listItem, setListItem] = useState(p.sd)
  const [fullData, setFullData] = useState<Opt<ServiceDescription>>()
  const { enqueueSnackbar } = useSnackbar()
  const [editing, setEditing] = useState(false)
  const [saving, setSaving] = useState(false)
  const [confirmingDelete, setConfirmingDelete] = useState(false)
  const classes = useStyles()

  async function handleUpdate(changes: ServiceDescriptionBase) {
    try {
      setSaving(true)

      if (p.sd.isTemplate) {
        await p.onSaveTemplate(p.sd, changes)
      } else {
        await api.updateServiceDescriptionById(p.sd.id, changes)
      }

      setListItem({...listItem, ...changes})
      setFullData({...fullData!, ...changes})
    } catch (err) {
      console.log(`Error updating service description: ${JSON.stringify(err)}`)
      enqueueSnackbar(t('errSave', {message: getErrorMessage(err)}), snacky.errorOpts)
    } finally {
      setSaving(false)
      setEditing(false)
    }
  }

  async function handleDelete() {
    try {
      setSaving(true)
      await api.deleteServiceDescriptionById(p.sd.id)
      p.onDeleted(p.sd)
    } catch (err) {
      console.log(`Error deleting service description: ${JSON.stringify(err)}`)
      enqueueSnackbar(t('errSave', {message: getErrorMessage(err)}), snacky.errorOpts)
    } finally {
      setSaving(false)
    }
  }

  async function handleExpansion(sd: ServiceDescriptionBrief, expanded: boolean) {
    if (expanded && !sd.isTemplate && !fullData) {
      try {
        const description = await api.getServiceDescriptionById(sd.id)
        setFullData(description)
      } catch (err) {
        console.log(`Error fetching service description: ${JSON.stringify(err)}`)
        enqueueSnackbar(t('errFetchServiceDescription', {message: getErrorMessage(err)}), snacky.errorOpts)
      }
    }
  }

  return (
    <Accordion
      elevation={0} // Otherwise the Accordion would look like a card on top of a card.
      className={classes.accordion}
      square={true}
      onChange={(_event, expanded: boolean) => handleExpansion(listItem, expanded)}
    >
      <AccordionSummary
        expandIcon={<ExpandMore/>}
      >
        <Typography variant="subtitle1">{`${listItem.serviceCode}\u2002${listItem.name}`}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box display='flex' flexDirection='column' flexGrow={1}>
          <Box display='flex' flexDirection='row' flexGrow={1} alignItems='center' flexWrap='nowrap'>
            <Box display='flex' flexDirection='column' flexGrow={1}>
              <Typography variant="h6">{t('sdcTitle')}</Typography>
            </Box>
            <TIButton
              edge={listItem.isTemplate ? 'end' : undefined}
              onClick={() => setEditing(true)}
              icon={<Edit/>}
              tooltipKey='sdcEditTooltip'
            />
            { !listItem.isTemplate &&
            <TIButton
              edge='end'
              onClick={() => setConfirmingDelete(true)}
              icon={<DeleteOutlined/>}
              tooltipKey='sdcDeleteTooltip'
            />
            }
          </Box>
          <MarkDownField label={t('sdcLabelInContract')} md={fullData?.inContract}/>
          <MarkDownField label={t('sdcLabelNotInContract')} md={fullData?.notInContract}/>
          <MarkDownField label={t('sdcLabelAdditionalInformation')} md={fullData?.additionalInformation}/>
          <FilesCard
            disabled={listItem.isTemplate}
            embedded={true}
            cardId='sdcFiles'
            title={t('sdcLabelAttachments')}
            files={fullData?.files ?? []}
            fileSaver={(file, description) => api.addServiceDescriptionFile(listItem.id, file, description)}
            fileUpdater={(fileId, description) => api.updateFileAttachment(fileId, description)}
            newFileType='SERVICE_DESCRIPTION'
          />
          {
            editing &&
            <DescriptionDialog
              sd={fullData ?? listItem}
              disabled={saving}
              onCancel={() => setEditing(false)}
              onSave={handleUpdate}
            />
          }
          {
            confirmingDelete &&
            <ConfirmDialog
              open={true}
              title={t('sdcDeleteConfirmTitle')}
              text={t('sdcDeleteConfirmText', {name: listItem.name})}
              onAction={(yes) => {
                setConfirmingDelete(false)

                if (yes === true) {
                  handleDelete()
                }
              }}
            />
          }
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

interface ServiceDescriptionInput {
  serviceCode: Opt<string>
  name: Opt<string>
  additionalInformation: Opt<string>
  inContract: Opt<string>
  notInContract: Opt<string>
}

interface DescriptionDialogProp {
  sd?: ServiceDescription
  onCancel: () => void
  onSave: (sd: ServiceDescriptionBase) => void
  disabled: boolean
}

function DescriptionDialog(p: DescriptionDialogProp) {
  const { t } = useTranslation()
  const [data, setData] = useState<ServiceDescriptionInput>({
    serviceCode: p.sd?.serviceCode ?? '',
    name: p.sd?.name,
    additionalInformation: p.sd?.additionalInformation ?? '',
    inContract: p.sd?.inContract ?? '',
    notInContract: p.sd?.notInContract ?? ''
  })
  const [pgName, setPgName] = useState<Opt<string>>(undefined)
  const editing = p.sd !== undefined && !p.sd.isTemplate
  const isDataValid = () => blankAsUndefined(data.serviceCode) !== undefined && blankAsUndefined(data.name) !== undefined

  return (
    <Dialog
      open={true}
      onClose={p.disabled ? undefined : p.onCancel}
      fullWidth={true}
      maxWidth='sm'
    >
      <DialogTitle>{t(editing ? 'sdcDialogTitleEdit' : 'sdcDialogTitleAdd')}</DialogTitle>
      <DialogContent>
        <Grid container direction='column' spacing={2}>
          <ProductGroupSelectorItem
            disabled={p.disabled}
            label={t('sdcLabelServiceCode')}
            initial={generateProductCode(data)}
            onChange={pg => {
              // Name field can be used for overriding the default ProductGroup.name.
              // Use the select group name if it still the same as the previously selected group's name.
              let n = blankAsUndefined(data.name)
              if (n === pgName) n = pg?.name
              setPgName(pg?.name)

              setData({
                ...data,
                serviceCode: pg?.productGroupCode,
                name: n ?? pg?.name ?? ''
              })
            }}
          />
          <TextItem
            name='name'
            disabled={p.disabled}
            label={t('sdcLabelName')}
            value={blankAsUndefined(data.name) ?? ''}
            maxLength={255}
            onChange={event => setData({...data, name: event.target.value})}
          />
          <TextItem
            name='inContract'
            disabled={p.disabled}
            label={t('sdcLabelInContract')}
            value={data.inContract}
            maxLength={3000}
            multiline={true}
            onChange={event => setData({...data, inContract: event.target.value})}
            infoTooltip={t('buttonShowMarkdownInstructionsToolTip')}
            infoTooltipHide={t('buttonHideMarkdownInstructionsToolTip')}
            infoBox={<MarkdownInstructions/>}
          />
          <TextItem
            name='notInContract'
            disabled={p.disabled}
            label={t('sdcLabelNotInContract')}
            value={data.notInContract}
            maxLength={3000}
            multiline={true}
            onChange={event => setData({...data, notInContract: event.target.value})}
            infoTooltip={t('buttonShowMarkdownInstructionsToolTip')}
            infoTooltipHide={t('buttonHideMarkdownInstructionsToolTip')}
            infoBox={<MarkdownInstructions/>}
          />
          <TextItem
            name='additionalInformation'
            disabled={p.disabled}
            label={t('sdcLabelAdditionalInformation')}
            value={data.additionalInformation}
            maxLength={3000}
            multiline={true}
            onChange={event => setData({...data, additionalInformation: event.target.value})}
            infoTooltip={t('buttonShowMarkdownInstructionsToolTip')}
            infoTooltipHide={t('buttonHideMarkdownInstructionsToolTip')}
            infoBox={<MarkdownInstructions/>}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={p.onCancel}
          disabled={p.disabled}
          color='primary'
        >
          {t('buttonCancel')}
        </Button>
        <Button
          onClick={() => {
            const cpy = Object.assign({}, data)
            cpy.name = blankAsUndefined(data.name) ?? '' // Shouldn't be empty at this point (isDataValid).
            p.onSave(convertInput(cpy))
          }}
          disabled={p.disabled || !isDataValid()}
          color='primary'
        >
          {t(editing ? 'buttonSave' : 'buttonAdd')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function convertInput(input: ServiceDescriptionInput): ServiceDescriptionBase {
  return {
    serviceCode: input.serviceCode!,
    name: input.name!,
    additionalInformation: blankAsUndefined(input.additionalInformation),
    inContract: blankAsUndefined(input.inContract),
    notInContract: blankAsUndefined(input.notInContract)
  }
}

function generateProductCode(data: ServiceDescriptionInput): Opt<ProductGroup> {
  if (data.serviceCode) {
    return {
      id: -1,
      name: data.name ?? '',
      productGroupCode: data.serviceCode,
      isActive: true
    }
  } else {
    return undefined
  }
}
