import React, { HTMLAttributeAnchorTarget, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, BoxProps, Grid, IconButton, IconButtonProps, InputAdornment, LinearProgress, makeStyles, TextField, TextFieldProps, Tooltip, Typography, TypographyProps } from '@material-ui/core'
import { isDefined, Opt } from '../tools/Utils'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { InfoOutlined } from '@material-ui/icons'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { MarkdownText } from './MarkdownText'

export function TableNoResultsCaption() {
  const { t } = useTranslation()
  return (<caption><div style={{textAlign: 'center'}}>{t('tableNoResults')}</div></caption>)
}

export interface CountingTextFieldProp {
  value: Opt<string>
  maxLength?: number
  onEnterPressed?: () => void
  info?: string
}

export function TextItem(prop: CountingTextFieldProp & TextFieldProps & Partial<InfoButtonProp>) {
  const {type, onEnterPressed, ...rest} = prop

  function handleKeyPress(event: React.KeyboardEvent) {
    if (prop.onEnterPressed && event.key === 'Enter') prop.onEnterPressed()
  }

  return (
    <Grid item>
      <CountingTextField
        fullWidth
        variant='outlined'
        type={prop.type ?? 'text'}
        onKeyPress={prop.onEnterPressed && handleKeyPress}
        {...rest}
      />
    </Grid>
  )
}

function getHelperText(helperText: Opt<ReactNode>, info: Opt<string>, contentLength: number, limit?: number) {
  if (helperText) return helperText

  // component='span' needs to be used as a) by default it is 'div' b) the parent of helperText is 'p' (which cannot be overridden when using TextField) and c) 'div' cannot be a child of 'p'.
  return (
    <Box key='a' component='span' display='flex' justifyContent='space-between' alignItems='flex-start'>
      <Box key='a.1' component='span' flexGrow={1}>{info ?? ''}</Box>
      { limit && <Box key='a.2' component='span' pl={info ? 2 : 0}>{`${contentLength}\u202F/\u202F${limit}`}</Box> }
    </Box>
  )
  // ^ Always include the first item, if 'info' isn't defined then its purpose is to align the second item to the left (surely there is a better way - but couldn't figure out the solution quickly).
}

const useTextFieldStyles = makeStyles((theme) => ({
  infoButton: {
    padding: '0px'
  }
}))

/**
 * Text field with character count. Can show info button with configurable tooltip and present a info box (also configurable) under itself.
 */
export function CountingTextField(prop: CountingTextFieldProp & TextFieldProps & Partial<InfoButtonProp>) {
  const classes = useTextFieldStyles();
  const [content, setContent] = useState(prop.value || '')
  const [infoBoxVisible, setInfoBoxVisible] = useState(false)
  const limit = prop.maxLength
  let {helperText, inputProps, onChange, InputProps, ...rest} = prop

  inputProps = inputProps || {}
  inputProps.maxLength = limit

  const infoAdornment = <InputAdornment position="end">
    <TIButton
      className={classes.infoButton}
      tooltipKey={infoBoxVisible && prop.infoTooltipHide ? prop.infoTooltipHide : prop.infoTooltip}
      icon={<InfoOutlined/>}
      onClick={() => setInfoBoxVisible(!infoBoxVisible)}
    />
  </InputAdornment>
  return (
    <Box>
      <TextField
        {...rest}
        inputProps={inputProps}
        helperText={getHelperText(prop.helperText, prop.info, content.length, limit)}
        FormHelperTextProps={{ variant: 'standard' }} // variant=standard eliminates the excessive helperText padding.
        onChange={(event) => {
          setContent(event.target.value)
          if (prop.onChange) prop.onChange(event)
        }}
        InputProps={{
          ...InputProps,
          endAdornment: !!prop.infoTooltip ? infoAdornment : undefined
        }}
      />
      { infoBoxVisible && !!prop.infoBox ? prop.infoBox : null }
    </Box>
  )
}

const useComboboxStyles = makeStyles((theme) => ({
  newOption: {
    fontWeight: 'bold'
  }
}))

type ComboboxOption = { inputValue: string, title: string }

export const toSimpleComboboxOption = (value: string) => ({inputValue: value, title: value})

const getStringValue = (option: string | ComboboxOption | null) => {
  if (option === null) {
    return ''
  }
  // Value selected with enter, right from the input
  if (typeof option === 'string') {
    return option
  }
  // Add "xxx" option created dynamically
  if (option.inputValue) {
    return option.inputValue
  }
  // Regular option
  return option.title
};

export interface ComboboxProp extends Omit<TextFieldProps, 'onChange'> {
  options: ComboboxOption[]
  value: Opt<ComboboxOption>
  disabled: boolean
  /** Defaults to true. */
  allowAddOption?: boolean
  /** If allowAddOption==false, this text is shown when there are no options matching to input */
  noOptionsText?: string
  getOptionLabel?: (option: ComboboxOption) => string
  getOptionSelected: (option: ComboboxOption, value: ComboboxOption) => boolean
  onChange: (event: object, value: string | null, reason: string) => void
  /** If left undefined, disables character counting */
  maxLength?: number
}

interface ComboboxInternalOption extends ComboboxOption {
  isAddNewOption?: boolean
}

// Basic Combobox implementation largely based on https://v4.mui.com/components/autocomplete/#creatable demo code.
export function CountingCombobox(prop: ComboboxProp) {
  const { t } = useTranslation()
  const classes = useComboboxStyles();
  const [content, setContent] = useState(prop.value || '')
  let {options, value, disabled, allowAddOption, getOptionSelected, onChange, noOptionsText, ...rest} = prop
  const canAddOption = allowAddOption ?? true

  const filter = createFilterOptions<ComboboxInternalOption>();

  return <Autocomplete
    options={options as ComboboxInternalOption[]}
    value={content}
    disabled={disabled}
    debug={true}
    filterOptions={(options, params) => {
      const filtered = filter(options, params);

      // Suggest the creation of a new value
      if (canAddOption && params.inputValue !== '') {
        filtered.push({
          inputValue: params.inputValue,
          title: `${t('comboboxAddOption')}: "${params.inputValue}"`,
          isAddNewOption: true
        });
      }

      return filtered;
    }}
    freeSolo={canAddOption}
    getOptionSelected={getOptionSelected}
    getOptionLabel={getStringValue}
    noOptionsText={noOptionsText ?? t('comboboxNoMatchingOptions')}
    onChange={(event, value, reason) => {
      setContent(getStringValue(value))
      if (onChange) onChange(event, getStringValue(value), reason)
    }}
    renderInput={(params) => {
      return <TextField
        {...params}
        {...rest}
        helperText={getHelperText(prop.helperText, undefined, getStringValue(content).length, prop.maxLength)}
        FormHelperTextProps={{ variant: 'standard' }} // variant=standard eliminates the excessive helperText padding.
        autoFocus={prop.autoFocus}
        variant='outlined'
      />
    }}
    renderOption={(opt: ComboboxInternalOption, _state) => (
      <Typography variant="subtitle1" className={opt.isAddNewOption ? classes.newOption : undefined}>
        {opt.title}
      </Typography>
    )}
  />
}

const useInfoButtonStyles = makeStyles((theme) => ({
  infoButton: {
    padding: theme.spacing(1.25, 1.25, 1.25, 1.25)
  }
}))

export interface InfoButtonProp {
  infoTooltip: string

  /** Tooltip to show when clicking would hide the info box. */
  infoTooltipHide?: string

  /** Component to be shown when the info button is clicked. */
  infoBox?: JSX.Element

  /**
   * Callback that is called with the current info box visibility state when
   * the button is clicked. This can be used to toggle custom infobox (e.g.
   * if needed to locate it further away from the button).
   */
  onInfoToggled?: (isShown: boolean) => void
}

export function InfoButton(prop: InfoButtonProp) {
  const classes = useInfoButtonStyles();
  const [infoBoxVisible, setInfoBoxVisible] = useState(false)

  return <>
    <TIButton
      className={classes.infoButton}
      tooltipKey={infoBoxVisible && prop.infoTooltipHide ? prop.infoTooltipHide : prop.infoTooltip}
      icon={<InfoOutlined/>}
      onClick={() => {
        setInfoBoxVisible(!infoBoxVisible)
        prop.onInfoToggled && prop.onInfoToggled(!infoBoxVisible)
      }}
    />
    { infoBoxVisible && !!prop.infoBox ? prop.infoBox : null }
  </>
}


const secondaryOpacity = 0.6
const useTextStyles = makeStyles((theme) => ({
  secondaryText: modStyle(theme.typography.body2, {opacity: secondaryOpacity, lineHeight: 1.25}),
  overlineText: modStyle(theme.typography.overline, {opacity: secondaryOpacity, lineHeight: 1.0}),
}))

export function SecondaryText(prop: TypographyProps) {
  const classes = useTextStyles()
  const {children, ...rest} = prop

  return (
    <Typography className={classes.secondaryText} {...rest}>{children}</Typography>
  )
}

export function OverlineText(prop: TypographyProps) {
  const classes = useTextStyles()
  const {children, ...rest} = prop

  return (
    <Typography className={classes.overlineText} {...rest}>{children}</Typography>
  )
}

function modStyle(src: CSSProperties, overrides: CSSProperties) {
  let cpy = Object.assign({}, src)
  return Object.assign(cpy, overrides)
}

export interface TIButtonProp {
  tooltipKey?: string
  ariaLabel?: string
  icon: React.ReactNode

  // TODO Couldn't figure out what the correct TIButton property type would be that would allow these properties too so just explicitly declare here.
  href?: string
  target?: HTMLAttributeAnchorTarget
  rel?: string
}

/**
 * Shorthand element for an IconButton with a tooltip.
 *
 * Use onClick property for in-app navigation on click of the icon, and href for external links. If both properties are defined, href is used.
 */
export function TIButton(p: TIButtonProp & IconButtonProps) {
  const {tooltipKey, ariaLabel, icon, href, onClick, ...rest} = p
  const { t } = useTranslation()
  const iconProps = isDefined(href) ? {href: href, ...rest} : {onClick: onClick, ...rest}

  const button = (
    <IconButton {...iconProps}>
      {p.icon}
    </IconButton>
  )

  if (tooltipKey && !(p.disabled ?? false)) {
    return <Tooltip title={t(tooltipKey) as String} aria-label={ariaLabel}>{button}</Tooltip>
  } else {
    return button
  }
}

export interface TIBoxProp {
  tooltipKey?: string
  ariaLabel?: string
  icon: React.ReactNode
}

/**
 * Shorthand element for a Boxed Icon with a tooltip.
 */
 export function TIBox(p: TIBoxProp & BoxProps & {edge?: false|'start'|'end'}) {
  const useStyles = makeStyles(theme => (
    {
      root: {
        lineHeight: '0.5', // This is a bit weird but seems to be needed for the wrapping element to not take too much height.
        paddingLeft: '12px',
        paddingRight: '12px',
        color: 'rgba(0, 0, 0, 0.54)',
      },
      edgeEnd: {
        marginRight: '-12px',
      },
      edgeStart: {
        marginLeft: '-12px',
      }
    }
  ))

  const {tooltipKey, ariaLabel, icon, ...rest} = p
  const { t } = useTranslation()
  const classes = useStyles()
  const classNames = [
    classes.root,
    p.edge === 'end' ? classes.edgeEnd : null,
    p.edge === 'start' ? classes.edgeStart : null,
  ].join(' ');

  const iconBox = (
    <Box {...rest} className={classNames}>
      {p.icon}
    </Box>
  )

  if (tooltipKey) {
    return <Tooltip title={t(tooltipKey) as String} aria-label={ariaLabel}>{iconBox}</Tooltip>
  } else {
    return iconBox
  }
}

export function MarkDownField({label, md}: {label?: string, md?: string}) {
  // Use remarkBreaks to interpret a "\n" as a line break (otherwise it is just a " " char). Without it one would need to use "\\\n" or "  \n".
  // remarkGfm enables autolinks, tables and strikethrough
  if (md) {
    return <Box mt={1}>
      { label && <Typography variant='subtitle2'>{label}</Typography> }
      <MarkdownText text={md} />
    </Box>
  } else {
    return null
  }
}

export function MarkdownInstructions() {
  const { t } = useTranslation()
  const useStyles = makeStyles(theme => (
    {
      container: {
        backgroundColor: '#F5F5F5',
        borderRadius: '4px',
        padding: '0.5rem',
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
      },
      item: {
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
        marginLeft: '0.5rem',
        marginRight: '0.5rem',
        whiteSpace: 'pre-wrap',
        maxWidth: '10rem',
      }
    }
  ))
  const classes = useStyles()

  return <Grid container direction='row' justifyContent='flex-start' className={classes.container}>
    <Grid item className={classes.item}>
      <Typography variant='subtitle2'>{t('markDownInstructionsHeading')}</Typography>
      <Typography variant='body2'># {t('markDownInstructionsHeadingLevel')} 1</Typography>
      <Typography variant='body2'>## {t('markDownInstructionsHeadingLevel')} 2</Typography>
    </Grid>
    <Grid item className={classes.item}>
      <Typography variant='subtitle2'>{t('markDownInstructionsList')}</Typography>
      <Typography variant='body2'>- {t('markDownInstructionsFirstItem')}</Typography>
      <Typography variant='body2'>- {t('markDownInstructionsSecondItem')}</Typography>
    </Grid>
    <Grid item className={classes.item}>
      <Typography variant='subtitle2'>{t('markDownInstructionsNumberedList')}</Typography>
      <Typography variant='body2'>1. {t('markDownInstructionsFirstItem')}</Typography>
      <Typography variant='body2'>1. {t('markDownInstructionsSecondItem')}</Typography>
    </Grid>
    <Grid item className={classes.item}>
      <Typography variant='subtitle2'>{t('markDownInstructionsMultilevelList')}</Typography>
      <Typography variant='body2'>1. {t('markDownInstructionsFirstItem')}</Typography>
      <Typography variant='body2'>    - {t('markDownInstructionsSubItemNeedsFourSpaces')}</Typography>
    </Grid>
    <Grid item className={classes.item}>
      <Typography variant='subtitle2'>{t('markDownInstructionsBold')}</Typography>
      <Typography variant='body2'>**{t('markDownInstructionsBold')}**</Typography>
    </Grid>
    <Grid item className={classes.item}>
      <Typography variant='subtitle2'>{t('markDownInstructionsItalics')}</Typography>
      <Typography variant='body2'>*{t('markDownInstructionsItalics')}*</Typography>
    </Grid>
    <Grid item className={classes.item}>
      <Typography variant='subtitle2'>{t('markDownInstructionsQuote')}</Typography>
      <Typography variant='body2'>&gt; {t('markDownInstructionsQuote')}</Typography>
    </Grid>
  </Grid>
}

export interface FilePickerProps {
  /** Defaults to false */
  disabled?: boolean
  allowMultiple?: boolean
  onSelection: (files: FileList) => void
  onCancel: () => void
}

export function FilePicker(prop: FilePickerProps) {
  const { t } = useTranslation()
  // Trying to fake the look of MUI Button by replicating the classes of such.
  const fakedButtonClasses = `${prop.disabled ? 'Mui-disabled': ''} MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary`
  return <div>
    <label htmlFor="filePicker" className={fakedButtonClasses}>
      {t('filePickerButtonLabel')}
    </label>
    <input
      id="filePicker"
      multiple={prop.allowMultiple ?? false}
      disabled={prop.disabled ?? false}
      type='file'
      onChange={event => isDefined(event.target.files) ? prop.onSelection(event.target.files) : prop.onCancel()}
      style={{ opacity: 0 /* Using opacity causes screen readers and such still interact with it. */}}
    />
  </div>
}

const useLinearProgressStyles = makeStyles(theme => (
  {
    container: {
      height: '10px',
      paddingTop: '6px'
    },
    indicator: {
      height: '4px'
    }
  }
))

export interface InlineLinearProgressProps {
  visible: boolean
}

export function InlineLinearProgress(prop: InlineLinearProgressProps) {
  const classes = useLinearProgressStyles()
  return <Box className={classes.container}>{prop.visible && <LinearProgress className={classes.indicator}/>}</Box>
}
