import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, CssBaseline, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

import './App.css'
import { SitesTable } from './components/SitesTable'
import { CustomersTable } from './components/CustomersTable'
import { SiteDetailsView } from './components/SiteDetailsView'
import { CustomerDetailsView } from './components/CustomerDetailsView'
import { SiteContractLinesTable } from './components/SiteContractLinesTable'
import { SitePriceListView } from './components/SitePriceListView'
import { ResponsiveDrawer } from './components/ResponsiveDrawer'
import { AppToolBar, ToolBarMenuAction } from './components/AppToolBar'
import { LogTable } from './components/LogTable'
import { SignInView } from './components/SigningInView'
import * as navigator from './tools/Navigator'
import { areCredentialsDefined, currentAuthenticatedParty, logout, setOnAuthenticationError, updateAuthenticatedParty } from './tools/Http'
import { PartySitesTable } from './components/PartySitesTable'
import { ContractLinesTable } from './components/ContractLinesTable'
import { log } from './tools/API';
import { SiteFileLinkDownloader } from './components/SiteFileLinkDownloader'
import { SiteGroupFileLinkDownloader } from './components/SiteGroupFileLinkDownloader'
import { SitesView } from './components/SitesView'
import { SiteGroupDetailsView } from './components/SiteGroupDetailsView'
import { SiteGroupSitesView } from './components/SiteGroupSitesView'

const useStyles = makeStyles(theme => (
  {
    root: {
      display: 'flex',
    },
    toolbar: theme.mixins.toolbar, // Necessary for content to be below app bar.
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }
))

window.onerror = (event, source, lineno, colno, error) => {
  if (error) {
    log(error.message, error.stack)
  }
  return true;
};

function App() {
  const [path, setPath] = useState(navigator.getRelativePath())
  const [updatingParty, setUpdatingParty] = useState(false)

  navigator.useListenPath('app', (newPath) => {
    console.log(`Current location changed to ${newPath} was '${path}'`)
    setPath(newPath)
  })

  setOnAuthenticationError(cause => {
    console.log(`Authentication error, cause=${cause}`)
    navigator.replace(navigator.PATH_SIGN_IN, { next: path, cause: cause })
  })

  const classes = useStyles()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const isBackableView = path.match('/(site|site-group|customer|party|contract)/\\d+') != null
  const isDetailsView = path.match('/(site|customer)/\\d+$') != null
  const isExportableView = path.match(`(?<![0-9])${navigator.PATH_CONTRACTLINES}/?$`) != null || path.match(`price-list/tab-1`) != null

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  if (path === navigator.PATH_SIGNED_OUT) {
    navigator.replaceWithNextOr(navigator.PATH_SIGN_IN)
  }

  if (path === navigator.PATH_SIGN_IN) {
    return <SignInView/>
  }

  // While update party information, display a "loading" screen. Once it is done
  // display the page defined by 'path'. Not sure if this is the nicest way of doing
  // things - perhaps a router library etc would make things more clear?
  if (updatingParty) {
    return <AuthenticatedPartyUpdater onSuccess={() => setUpdatingParty(false)}/>
  }

  const authenticated = currentAuthenticatedParty();

  if (areCredentialsDefined() && authenticated === undefined) {
    // Update authenticated party information before displaying the requested page.
    // This is needed to avoid having to log in on every page load (either refreshed
    // or arriving via link).
    setUpdatingParty(true)
    return (null)
  }

  if (!areCredentialsDefined() || authenticated === undefined) {
    const next = path !== navigator.PATH_SIGNED_OUT && path !== navigator.PATH_SIGN_IN
                    ? path : undefined;
    navigator.replace(navigator.PATH_SIGN_IN, { next: next })
    return null
  }

  if (!((authenticated?.isSiteManager ?? false) || (authenticated?.isAdmin ?? false))) {
    return <Unauthorized/>
  }

  const toolbarMenuActions = [];
  if (isDetailsView) {
    toolbarMenuActions.push(ToolBarMenuAction.DetailsCollapseAll)
    toolbarMenuActions.push(ToolBarMenuAction.DetailsExpandAll)
  }
  if (isExportableView) {
    toolbarMenuActions.push(ToolBarMenuAction.ExportToExcel)
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppToolBar
        onToggleDrawer={handleDrawerToggle}
        showBackButton={isBackableView}
        validMenuActions={toolbarMenuActions}
        />
      <ResponsiveDrawer mobileOpen={mobileOpen} onClose={handleDrawerToggle} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {getMainContent(path)}
      </main>
    </div>
  )
}

function getMainContent(path: string) {
  if (path === navigator.PATH_SITES) return <SitesView/>
  if (path === navigator.PATH_CUSTOMERS) return <CustomersTable/>
  if (path === navigator.PATH_CONTRACTLINES) return <ContractLinesTable/>
  if (path.includes(navigator.PATH_SITE_FILELINK)) return <SiteFileLinkDownloader path={path}/>
  if (path.includes(navigator.PATH_SITEGROUP_FILELINK)) return <SiteGroupFileLinkDownloader path={path}/>

  const pathMatchers: PathMatcher[] = [
    { pattern: /^\/site\/(\d+)\/contract-lines/, cc: (match) => <SiteContractLinesTable siteId={parseInt(match[1])}/>},
    { pattern: /^\/site\/(\d+)\/log/, cc: (match) => <LogTable siteId={parseInt(match[1])}/>},
    { pattern: /^\/site\/(\d+)$/, cc: (match) => <SiteDetailsView siteId={parseInt(match[1])}/> },
    { pattern: /^\/site-group\/(\d+)$/, cc: (match) => <SiteGroupDetailsView siteGroupId={parseInt(match[1])}/> },
    { pattern: /^\/site-group\/(\d+)\/sites/, cc: (match) => <SiteGroupSitesView siteGroupId={parseInt(match[1])}/> },
    { pattern: /^\/contract\/(\d+)\/price-list/, cc: (match) => <SitePriceListView contractId={parseInt(match[1])}/>},
    { pattern: /^\/customer\/(\d+)\/sites$/, cc: (match) => <SitesTable customerId={parseInt(match[1])}/> },
    { pattern: /^\/customer\/(\d+)$/, cc: (match) => <CustomerDetailsView customerId={parseInt(match[1])}/> },
    { pattern: /^\/party\/(\d+)\/sites/, cc: (match) => <PartySitesTable partyId={parseInt(match[1])}/>},
  ]

  for (let pm of pathMatchers) {
    let match = path.match(pm.pattern)
    if (match != null) {
      return pm.cc(match)
    }
  }

  return <SitesView/>
}

export default App;

interface PathMatcher {
  pattern: string | RegExp
  cc: (match: RegExpMatchArray) => JSX.Element
}

function Unauthorized() {
  const { t } = useTranslation()
  const [isCallbackSet, setCallBackSet] = useState(false)

  useEffect(() => {
    logout()
    if (!isCallbackSet) {
      setTimeout(() => navigator.replace(navigator.PATH_SIGN_IN), 5000)
      setCallBackSet(true)
    }
  }, [isCallbackSet])

  return (
    <Box display='flex' alignItems='center' flexGrow={1} flexDirection='column' m={2}>
      <div><Typography variant="body1">{t('errUnauthorized')}</Typography></div>
      <div><Typography variant="body2">{t('unauthorizedRedirectionLabel')}</Typography></div>
    </Box>
  )
}

interface AuthenticatedPartyUpdaterProps {
  onSuccess: () => void
}

function AuthenticatedPartyUpdater({onSuccess}: AuthenticatedPartyUpdaterProps) {

  useEffect(() => {

    async function doUpdateAuthenticatedParty() {
      try {
        await updateAuthenticatedParty()
        onSuccess()
      } catch (err) {
        console.log(`Failed to update authenticated party -> show login, ${err}`)
        navigator.replace(navigator.PATH_SIGN_IN)
      }
    }

    doUpdateAuthenticatedParty()
  }, [onSuccess])

  return (null)
}
