import * as api from './API'
import { handleDownload } from './Utils'

export interface DownloadAttachmentButtonProp {
  path: string
}

export async function downloadAttachmentWithUid(p: DownloadAttachmentButtonProp): Promise<number> {
  let metadataGetter: (fileUid: string) => Promise<api.FileAttachment>
  let blobGetter: (fileUid: string) => Promise<Blob>

  if (p.path.startsWith('/site/')) {
    metadataGetter = api.getSiteFileDataWithUid
    blobGetter = api.getSiteFileWithUid
  } else if (p.path.startsWith('/site-group/')) {
    metadataGetter = api.getSiteGroupFileDataWithUid
    blobGetter = api.getSiteGroupFileWithUid
  } else {
    throw new Error(`Unknown URL type: ${p.path}`)
  }

  const uid = p.path.replace(new RegExp("/[^/]+/file/"), "")
  let targetId = null

  try {
    const imgMetaData = await metadataGetter(uid);
    targetId = imgMetaData.targetId;
    const blob = await blobGetter(uid);
    handleDownload(blob, imgMetaData.filename, true);
  } catch (err) {
    console.error(`Error downloading file: ${JSON.stringify(err)}`);
  }
  return (targetId as number);
}