import { useMediaQuery, useTheme } from '@material-ui/core'
import { VisibilityOff, VisibilityOutlined } from '@material-ui/icons'
import { TFunction, useTranslation } from 'react-i18next'
import { searchSites } from '../tools/API'
import * as navigator from '../tools/Navigator'
import { BaseTable, ColumnDef } from './BaseTable'
import { useSetTitle } from '../tools/AppEvents'
import { TIBox } from './SmallComponents'
import { Site, PageResult, Idful } from '../tools/API'

class ConstructableSite implements Idful {
  id: number
  site: Site
  contractNumbers: string
  constructor(site: Site) {
    this.id = site.id
    this.site = site
    this.contractNumbers = site.contracts.map((c) => c.externalId).join(', ')
  }
}

class ConstructablePageResult<T> implements PageResult<T> {
  results: T[]
  page: number
  totalResults: number
  lastMatch?: (number|string)[]
  constructor(results: T[], page: number, totalResults: number, lastMatch?: (number|string)[]) {
    this.results = results
    this.page = page
    this.totalResults = totalResults
    this.lastMatch = lastMatch
  }
}

const columnsWide: ColumnDef[] = [
  { width: 21, field: 'site.name', headerTextId: 'sitesHeaderName', sortBy: 'NAME' },
  { width: 12, field: 'site.siteGroup.name', headerTextId: 'sitesHeaderSiteGroupName', sortBy: 'SITE_GROUP_NAME'},
  { width: 9, field: 'contractNumbers', headerTextId: 'sitesHeaderContractExtId', sortBy: 'CONTRACT_NUMBERS' },
  { width: 13, field: 'site.customer.name', headerTextId: 'sitesHeaderCustomerName', sortBy: 'CUSTOMER_NAME' },
  { width: 13, field: 'site.streetAddress', headerTextId: 'sitesHeaderStreet', sortBy: 'STREET_ADDRESS' },
  { width: 6, field: 'site.postCode', headerTextId: 'sitesHeaderZip', sortBy: 'POST_CODE' },
  { width: 8, field: 'site.city', headerTextId: 'sitesHeaderCity', sortBy: 'CITY' },
  { width: 9, field: 'site.externalId', headerTextId: 'sitesHeaderExtId', sortBy: 'EXTERNAL_ID' },
  { width: 9, field: 'site.isPublished', headerTextId: 'sitesHeaderPublished', sortBy: 'IS_PUBLISHED'},
]

const columnsNarrow: ColumnDef[] = [
  { width: 49, field: 'site.name', headerTextId: 'sitesHeaderName', sortBy: 'NAME' },
  { width: 13, field: 'contractNumbers', headerTextId: 'sitesHeaderContractExtId', sortBy: 'CONTRACT_NUMBERS' },
  { width: 13, field: 'site.city', headerTextId: 'sitesHeaderCity', sortBy: 'CITY' },
  { width: 12, field: 'site.isPublished', headerTextId: 'sitesHeaderPublished', sortBy: 'IS_PUBLISHED'},
]

const visibleMarker = <TIBox tooltipKey={'sitesTableVisible'} icon={<VisibilityOutlined/>}/>
const notVisibleMarker = <TIBox tooltipKey={'sitesTableNotVisible'} icon={<VisibilityOff/>}/>

function mapValue(t: TFunction<"translation">, field: string, value: any) {
  if (field === 'site.isPublished') {
    return value === true ? visibleMarker : notVisibleMarker;
  } else {
    return value
  }
}

export interface SitesTableProp {
  customerId?: number
}

export function SitesTable(p: SitesTableProp) {
  const { t } = useTranslation()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const columns = matches ? columnsWide : columnsNarrow
  const tableId = `sites-${p.customerId ?? 'all'}`

  useSetTitle(t('drawerMenuSites'))

  return (
    <BaseTable
      key={tableId} // Without the key table searchArgs aren't loaded from storage (initialized) when one navigates from customer specific sites to all sites.
      tableId={tableId}
      columns={columns}
      filterLabelId='sitesSearchLabel'
      onOpenDetails={site => navigator.push(`/site/${site.id}`, {site: site})}
      onFetchItems={args => searchSites(args, {customerId: p.customerId}).then(pageResult => {
        const transformedSites = pageResult.results.map((site: Site) => new ConstructableSite(site));
        return new ConstructablePageResult<ConstructableSite>(transformedSites, pageResult.page, pageResult.totalResults, pageResult.lastMatch);
      })}
      valueMapper={(field, value) => mapValue(t, field, value)}
    />
  )
}
