import { useEffect, useState } from 'react'
import { FileCopyOutlined } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, Container, InputAdornment, TextField } from '@material-ui/core'

import { CountingCombobox, FilePicker, MarkdownInstructions, SecondaryText, TextItem, TIButton, toSimpleComboboxOption } from './SmallComponents'
import { Opt, blankAsUndefined } from '../tools/Utils'
import { FileAttachment } from '../tools/API'
import * as snacky from './CustomSnackbarProvider'
import * as api from '../tools/API'
import { getKohdekansioLink } from '../tools/Navigator'

function getFileLinkPrefix(fileType: api.FileAttachmentType) {
  switch (fileType) {
    case 'SITE':
      return '/site'
    case 'SITE_GROUP':
      return '/site-group'
    default:
      throw new Error(`File link for type ${fileType} is not supported yet`)
  }
}

function getFileLinkGenerator(fileType: api.FileAttachmentType) {
  switch (fileType) {
    case 'SITE':
      return api.generateSiteFileLink
    case 'SITE_GROUP':
      return api.generateSiteGroupFileLink
    default:
      throw new Error(`Generating file link for type ${fileType} is not supported yet`)
  }
}

export interface AddAttachmentDialogProp {
  file: Opt<FileAttachment>
  categoryOptions: string[]
  onSave: (file: Opt<File>, description: Opt<string>, category: Opt<string>) => void
  onSaveMany: (files: File[]) => void
  onCancel: () => void
  saving?: boolean
  disabled?: boolean
  showCategory?: boolean
  targetId?: number
  fileType: api.FileAttachmentType
  allowReplace?: boolean
}

export function AddAttachmentDialog(p: AddAttachmentDialogProp) {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [description, setDescription] = useState<string>(p.file?.description ?? '')
  const [category, setCategory] = useState<Opt<string>>(p.file?.category)
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const disabled = (p.saving ?? false) || (p.disabled ?? false)
  const showCategory = p.showCategory ?? false
  const isEditing = p.file !== undefined
  const allowReplace = p.allowReplace ?? false
  const [isReplacing, setReplacing] = useState(false)
  const [fileIsTooLarge, setFileIsToolarge] = useState<File[]>([]);
  const [fileLinkExists, setFileLinkExists] = useState(false);
  const [generatingFileLink, setGeneratingFileLink] = useState(false);
  const [firstTimeFileLink, setFirstTimeFileLink] = useState(false);
  const targetId = p.targetId
  const fileType = p.fileType
  const [fileAttachmentLink, setFileAttachmentLink] = useState("");

  useEffect(() => {
    if (p.file?.publicUid !== undefined) {
      const linkPrefix = getFileLinkPrefix(fileType)
      setFileAttachmentLink(getKohdekansioLink(`${linkPrefix}/file/${p.file.publicUid}`));
      setFileLinkExists(true);
      setFirstTimeFileLink(false);
    }
  }, [p.file, fileType])

  const onClose = (cb: () => void) => {
    cb()

    // Clear selections in case dialog gets opened again.
    setDescription('')
    setCategory('')
    setSelectedFiles([])
  }

  const handleSelectedFiles = (files: FileList) => {
    const selected: File[] = []
    const tooLarge: File[] = []
    for(let i = 0; i < files.length; i++) {
      const file = files.item(i)
      if (file === null) break;
      if (file.size > api.MAX_UPLOAD_FILE_SIZE) {
        tooLarge.push(file)
      }
      else {
        selected.push(file)
      }
    }
    setFileIsToolarge(tooLarge)
    setSelectedFiles(selected)
  }

  const handleCopyToClipboard = async () => {

    try{
      await navigator.clipboard.writeText(fileAttachmentLink);
      enqueueSnackbar((t('fileDialogLinkCopiedInfo')), snacky.infoOpts)
    } catch (err) {
      console.log(`Error copying link: ${JSON.stringify(err)}`)
    }
  };

  const onSave = () => onClose(() => {
    if (selectedFiles.length <= 1) {
      p.onSave(selectedFiles[0], blankAsUndefined(description), blankAsUndefined(category))
    } else {
      p.onSaveMany(selectedFiles)
    }
  })
  const onCancel = () => {
    onClose(p.onCancel)
    if (fileLinkExists && firstTimeFileLink) {
      window.location.reload();
    }
  }

  async function generateFileLink(fileType: api.FileAttachmentType, targetId: number, fileId: number) {
    const linkGenerator = getFileLinkGenerator(fileType)
    const linkPrefix = getFileLinkPrefix(fileType)

    try{
      setGeneratingFileLink(true);
      const linkresponse = await linkGenerator(targetId, fileId);
      setFileAttachmentLink(getKohdekansioLink(`${linkPrefix}/file/${linkresponse.publicUid}`))
      setFirstTimeFileLink(true);
      setGeneratingFileLink(false);
      setFileLinkExists(true);
    } catch (err) {
      console.error(`Error generating link: ${JSON.stringify(err)}`)
    }
  }

  return (
    <Dialog
      open={true}
      onClose={disabled ? undefined : onCancel}
      fullWidth={true}
      maxWidth='md'
    >
      <DialogTitle>{t(isEditing ? 'fileDialogTitleEdit' : 'fileDialogTitleAdd')}</DialogTitle>
      <DialogContent>
        <Grid container direction='column' spacing={2}>
          { !isEditing &&
          <Grid item>
            <SecondaryText style={{whiteSpace: 'pre-line'}}>{t('fileDialogInfoTextTip')}</SecondaryText>
          </Grid>
          }
          <Grid item>
            { isEditing ?
              isReplacing ?
                <FilePicker
                  disabled={disabled}
                  allowMultiple={false}
                  onSelection={handleSelectedFiles}
                  onCancel={() => setSelectedFiles([])}
                />
                :
                <SecondaryText>{p.file?.filename ?? ''}</SecondaryText>
              :
              <FilePicker
                disabled={disabled}
                allowMultiple={true}
                onSelection={handleSelectedFiles}
                onCancel={() => setSelectedFiles([])}
              />
          }
          </Grid>
          { (!isEditing || isReplacing) &&
            <Grid item>
              {selectedFiles.map((file, index) => <Typography key={index} variant='body2'>{file.name}</Typography>)}
            </Grid>
          }
          { (!isEditing || isReplacing) && fileIsTooLarge.length > 0 &&
            <Grid item>
              <Container style={{backgroundColor: '#f8d547', borderRadius: 3, padding: 8}}>
                <Typography variant='body2'>
                  {t('fileDialogInfoTextSize')}
                </Typography>
                  {fileIsTooLarge.map((file, index) => <Typography key={index} variant='body2'>{file.name}</Typography>)}
              </Container>
            </Grid>
          }
          { selectedFiles.length <= 1 &&
            <>
              <TextItem
                name='description'
                disabled={disabled}
                label={t('fileDialogLabelDescription')}
                value={description}
                maxLength={3000}
                multiline={true}
                onChange={event => setDescription(event.target.value)}
                autoFocus={p.file !== undefined}
                infoTooltip={t('buttonShowMarkdownInstructionsToolTip')}
                infoTooltipHide={t('buttonHideMarkdownInstructionsToolTip')}
                infoBox={<MarkdownInstructions/>}
              />
              { showCategory && <Grid item>
                <CountingCombobox
                  name='category'
                  options={p.categoryOptions.map(toSimpleComboboxOption)}
                  value={toSimpleComboboxOption(category ?? '')}
                  disabled={disabled}
                  getOptionSelected={(opt, value) => { return opt.inputValue.toLocaleLowerCase() === value?.inputValue?.toLocaleLowerCase()}}
                  onChange={(_event, value) => { setCategory(value)}}
                  maxLength={255}
                  label={t('fileDialogLabelCategory')}
                />
              </Grid>
              }
            </>
          }
        { generatingFileLink ?
            <CircularProgress variant="indeterminate" size={30} />
           :
            isEditing && fileLinkExists &&
            <Grid item>
              <Box>
                <TextField
                    name='fileLink'
                    disabled={true}
                    style={{width: '100%'}}
                    label={(t('fileDialogLabelLink'))}
                    variant='outlined'
                    value={fileAttachmentLink}
                    onChange={event => setDescription(event.target.value)}
                    autoFocus={p.file !== undefined}
                    InputProps={{
                      endAdornment: (<InputAdornment position="end">
                        <TIButton
                          icon={<FileCopyOutlined/>}
                          onClick={handleCopyToClipboard}
                        />
                      </InputAdornment>)
                      }}
                />
              </Box>
            </Grid>
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        { isEditing && allowReplace && !isReplacing && <Button
            disabled={disabled}
            onClick={() => setReplacing(true)}
            color='primary'
          >
            {t('fileDialogButtonReplaceFile')}
          </Button>
        }
        {
          isEditing && !fileLinkExists &&
            <Button
              color='primary'
              onClick={() => generateFileLink(fileType!, targetId!, p.file?.id!)}
              disabled={fileType === undefined || targetId === undefined || p.file?.id === undefined}
            >
              {t('fileDialogGenerateLink')}
            </Button>
        }
        <Button onClick={onCancel} disabled={disabled} color='primary'>{t('buttonCancel')}</Button>
        <Button
          onClick={onSave}
          disabled={disabled || (!isEditing && selectedFiles.length === 0)}
          color='primary'
        >
          {p.saving ? <CircularProgress variant="indeterminate" size={30} /> : t(isEditing ? 'buttonSave' : 'buttonAdd')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
