import { useState } from 'react'
import { Button, CircularProgress, useMediaQuery, useTheme } from '@material-ui/core'
import { VisibilityOff, VisibilityOutlined } from '@material-ui/icons'
import { TFunction, useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

import { addSiteToGroup, searchSites } from '../tools/API'
import { BaseTable, ColumnDef } from './BaseTable'
import { dispatchAppEvent, useSetTitle } from '../tools/AppEvents'
import { TIBox } from './SmallComponents'
import { Site, PageResult, Idful } from '../tools/API'
import { getErrorMessage } from '../tools/Utils'
import * as snacky from './CustomSnackbarProvider'

class ConstructableSite implements Idful {
  id: number
  site: Site
  contractNumbers: string
  constructor(site: Site) {
    this.id = site.id
    this.site = site
    this.contractNumbers = site.contracts.map((c) => c.externalId).join(', ')
  }
}

class ConstructablePageResult<T> implements PageResult<T> {
  results: T[]
  page: number
  totalResults: number
  lastMatch?: (number|string)[]
  constructor(results: T[], page: number, totalResults: number, lastMatch?: (number|string)[]) {
    this.results = results
    this.page = page
    this.totalResults = totalResults
    this.lastMatch = lastMatch
  }
}

const columnsWide: ColumnDef[] = [
  { width: 24, field: 'site.name', headerTextId: 'sitesHeaderName', sortBy: 'NAME' },
  { width: 10, field: 'contractNumbers', headerTextId: 'sitesHeaderContractExtId', sortBy: 'CONTRACT_NUMBERS' },
  { width: 15, field: 'site.customer.name', headerTextId: 'sitesHeaderCustomerName', sortBy: 'CUSTOMER_NAME' },
  { width: 15, field: 'site.streetAddress', headerTextId: 'sitesHeaderStreet', sortBy: 'STREET_ADDRESS' },
  { width: 8, field: 'site.postCode', headerTextId: 'sitesHeaderZip', sortBy: 'POST_CODE' },
  { width: 8, field: 'site.city', headerTextId: 'sitesHeaderCity', sortBy: 'CITY' },
  { width: 10, field: 'site.externalId', headerTextId: 'sitesHeaderExtId', sortBy: 'EXTERNAL_ID' },
  { width: 10, field: 'site.isPublished', headerTextId: 'sitesHeaderPublished', sortBy: 'IS_PUBLISHED'},
]

const columnsNarrow: ColumnDef[] = [
  { width: 49, field: 'site.name', headerTextId: 'sitesHeaderName', sortBy: 'NAME' },
  { width: 13, field: 'contractNumbers', headerTextId: 'sitesHeaderContractExtId', sortBy: 'CONTRACT_NUMBERS' },
  { width: 13, field: 'site.city', headerTextId: 'sitesHeaderCity', sortBy: 'CITY' },
  { width: 12, field: 'site.isPublished', headerTextId: 'sitesHeaderPublished', sortBy: 'IS_PUBLISHED'},
]

const visibleMarker = <TIBox tooltipKey={'sitesTableVisible'} icon={<VisibilityOutlined/>}/>
const notVisibleMarker = <TIBox tooltipKey={'sitesTableNotVisible'} icon={<VisibilityOff/>}/>

function mapValue(t: TFunction<"translation">, field: string, value: any) {
  if (field === 'site.isPublished') {
    return value === true ? visibleMarker : notVisibleMarker;
  } else {
    return value
  }
}

export interface SiteGroupSitesAdditionTableProp {
  siteGroupId: number
}

export function SiteGroupSitesAdditionTable(p: SiteGroupSitesAdditionTableProp) {
  const { t } = useTranslation()
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const [actionInProgress, setActionInProgress] = useState(false)
  const columns = matches ? columnsWide : columnsNarrow

  function addSites(sites: ConstructableSite[]) {
    setActionInProgress(true)
    Promise.all(sites.map(site => addSite(site)))
      .then(response => {
        enqueueSnackbar((t('siteGroupSitesAddedInfo')), snacky.infoOpts)
        dispatchAppEvent('RefreshResults')
      })
      .finally(() => setActionInProgress(false))
  }

  async function addSite(site: ConstructableSite) {
    try {
      await addSiteToGroup(p.siteGroupId, site.id)
    } catch (err) {
      console.log(`Error adding site to group: ${JSON.stringify(err)}`)
      enqueueSnackbar(t('errAddSiteToGroup', {message: getErrorMessage(err)}), snacky.errorOpts)
    }
  }

  useSetTitle(t('drawerMenuSites'))

  return (
    <BaseTable
      tableId="siteGroupSitesAdditionTable"
      columns={columns}
      filterLabelId='sitesSearchLabel'
      onFetchItems={args => searchSites(args, {notInGroup: true}).then(pageResult => {
        const transformedSites = pageResult.results.map((site: Site) => new ConstructableSite(site));
        return new ConstructablePageResult<ConstructableSite>(transformedSites, pageResult.page, pageResult.totalResults, pageResult.lastMatch);
      })}
      valueMapper={(field, value) => mapValue(t, field, value)}
      selectable={true}
      selectionAction={(selectedItems: ConstructableSite[]) => {
        return <SiteAdditionButton onClick={() => addSites(selectedItems)} disabled={selectedItems.length === 0} inProgress={actionInProgress} />
      }}
    />
  )
}

interface SiteAdditionButtonProp {
  onClick: (value: any) => void
  disabled: boolean
  inProgress: boolean
}

function SiteAdditionButton(p: SiteAdditionButtonProp) {
  const { t } = useTranslation()

  return (
    <Button
      color="primary"
      disabled={p.disabled || p.inProgress}
      onClick={p.onClick}
    >{p.inProgress ? <CircularProgress variant="indeterminate" size={30} /> : t('siteGroupSiteAdditionButtonLabel')}</Button>
  )
}
