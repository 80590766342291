import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { HomeWorkOutlined } from '@material-ui/icons'
import { useSnackbar } from 'notistack'

import * as navigator from '../tools/Navigator'
import * as api from '../tools/API'
import { FileAttachment } from '../tools/API'
import * as snacky from './CustomSnackbarProvider'
import { getErrorMessage } from '../tools/Utils'
import { useSetTitle } from '../tools/AppEvents'
import { BackdropLoadingSpinner } from './BackdropLoadingSpinner'
import { SiteGroupInfoCard } from './SiteGroupInfoCard'
import { FilesCard } from './FilesCard'
import { LinkCard } from './LinkCard'

interface SiteGroupDetailsState {
    siteGroup?: api.SiteGroup
  }
  
  interface SiteGroupDetailsProp {
    siteGroupId: number
  }
  
  export function SiteGroupDetailsView(prop: SiteGroupDetailsProp) {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const [group, setGroup] = useState(((navigator.getCurrentState() ?? {}) as SiteGroupDetailsState).siteGroup)
    const [isLoading, setLoading] = useState<boolean>(false)
    
    async function loadSiteGroup() {
      try {
        setLoading(true)
        let group = await api.getSiteGroupById(prop.siteGroupId)
        setGroup(group)
        setLoading(false)
      } catch (err) {
        console.log(`Error fetching: ${JSON.stringify(err)}`)
        enqueueSnackbar(t('errFetchSiteGroup', {message: getErrorMessage(err)}), snacky.errorOpts)
        setLoading(false)
      }
    }
  
    useEffect(() => {
      // Site group search results do not include 'sites'.
      if (group === undefined || group.sites === undefined) {
        loadSiteGroup()
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group])
  
    useSetTitle(group?.name ?? '')
  
    return (
      <Grid container spacing={3} direction="row">
        <BackdropLoadingSpinner open={isLoading} message={t('siteLoadingMsg')} />
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} direction="column" >
            <Grid item>
              <SiteGroupInfoCard siteGroup={group}/>
            </Grid>
            <Grid item>
              <LinkCard
                cardId='siteGroupSites'
                title={t('siteGroupSitesCardTitle')}
                icon={<HomeWorkOutlined/>}
                onClick={() => navigator.push(`/site-group/${group!.id}/sites`, {title: t('siteGroupSitesTableTitle', {name: group!.name})})}
                />
    
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2} direction="column" >
            <Grid item>
              <SiteGroupFilesCard groupId={prop.siteGroupId} files={group?.files ?? []}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  
  interface SiteGroupFilesCardProp {
    groupId: number
    files: FileAttachment[]
  }
  
  function SiteGroupFilesCard(p: SiteGroupFilesCardProp) {
    const { t } = useTranslation()
  
    return (
      <FilesCard
        cardId='siteGroupFiles'
        title={t('siteGroupAttachmentsTitle')}
        grouped={true}
        files={p.files}
        fileSaver={(file, description, category) => api.addSiteGroupFile(p.groupId, file, description, category)}
        fileUpdater={(fileId, description, category) => api.updateSiteGroupFile(p.groupId, fileId, description, category)}
        fileReplacer={(fileId, file, description, category) => api.replaceSiteGroupFile(p.groupId, fileId, file, description, category)}
        targetId={p.groupId}
        newFileType='SITE_GROUP'
      />
    )
}
