import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { LocationCity } from '@material-ui/icons'
import { useSnackbar } from 'notistack'

import * as api from '../tools/API'
import * as snacky from './CustomSnackbarProvider'
import { getErrorMessage } from '../tools/Utils'
import { DetailsCard } from './DetailsCard'
import { LabeledItem } from './LabeledItem'

export function SiteGroupInfoCard({siteGroup}: {siteGroup?: api.SiteGroup}) {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const [description, setDescription] = useState('')
  
    useEffect(() => { setDescription(siteGroup?.description ?? '') }, [siteGroup?.description])
  
    async function saveDescription(description: string) {
      try {
        await api.updateSiteGroupDescription(siteGroup!.id, {description: description})
        siteGroup!.description = description
        setDescription(description)
      } catch (err) {
        console.log(`Failed to save description: ${JSON.stringify(err)}`)
        enqueueSnackbar(t('errSave', {message: getErrorMessage(err)}), snacky.errorOpts)
      }
    }
  
    return ( /* TODO: fix icons */
      <DetailsCard cardId='siteGroup' title={t('siteGroupTitle')} icon={<LocationCity/>}>
        { siteGroup &&
        <Grid container direction='column' spacing={1}>
          <LabeledItem label={t('siteGroupLabelName')} value={siteGroup.name} />
          <LabeledItem
            label={t('siteGroupLabelDescription')}
            value={description}
            onSave={description => saveDescription(description)}
            maxLength={3000}
          />
        </Grid>
        }
      </DetailsCard>
    )
  }
  