import React, { useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Checkbox, CircularProgress, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField } from '@material-ui/core'
import { Clear, Search } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroller'
import { useSnackbar } from 'notistack'

import { Idful, PageResult, SearchArgs } from '../tools/API'
import { getErrorMessage, isDefined, Opt, resolvePath, selectionIgnoringClick } from '../tools/Utils'
import * as snacky from './CustomSnackbarProvider'
import { useDebouncedCallback } from '../tools/ReactUtils'
import { InfoButton, InfoButtonProp, InlineLinearProgress, TIButton } from './SmallComponents'
import { useAppEventListener } from '../tools/AppEvents'

export interface ColumnDef {
  width?: number
  field: string
  headerTextId: string
  sortBy?: string
}

interface InternalColumnDef extends ColumnDef {
  isCheckbox?: boolean
}

const useStyles = makeStyles(theme => {
  const cellPadding = theme.spacing(1.5, 1.5, 1.5, 1.5)

  return {
    table: {
      minWidth: 200
    },
    root: {
      minHeight: 400
    },
    row: {
      cursor: 'pointer'
    },
    filterCell: {
      padding: 0
    },
    filterCellContent: {
      display: 'flex',
      padding: theme.spacing(1.25, 1.25, 0, 1.25),
      justifyContent: 'space-between',
      verticalAlign: 'top',
      '& input': {
        paddingTop: '11px',
        paddingBottom: '11px'
      },
      '& div': {
        fontWeight: 'normal',
        textWrap: 'nowrap',
      },
      '& .MuiTextField-root': {
        width: '20rem'
      }
    },
    numberOfResults: {
      display: 'inline-flex',
      alignItems: 'center',
      height: '3em'
    },
    filterLabel: {
      // Hackish solution for adjusting the label to not overlap startAdornment. Adapted from https://stackoverflow.com/a/66827795
      transform: "translate(48px, 13px) scale(1)"
    },
    cell: {
      padding: cellPadding,

      // The following are used to truncate too wide cell contents / disable wrapping to multiple lines (to keep the table height constant between pages).
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: '1px'
    },
    rowSelector: {
      // These counter the additional height of the input compared to visibility icon, thus keeping row height the same.
      paddingTop: 0,
      paddingBottom: 0
    }
  }
})

const selectorColumn: InternalColumnDef = {
  field: 'selector',
  headerTextId: '',
  isCheckbox: true
}

type ColumnValueMapper<T> = (field: string, value: any, item: T) => string|number

const getColumnWidth = (column: InternalColumnDef) => {
  if (column.isCheckbox) {
    return '5%'
  } else if (column.width) {
    return `${column.width}%`
  }
  return undefined
}

export interface BaseTableProp<T extends Idful> {
  /** Identifier for this table, should be unique. */
  tableId: string
  columns: ColumnDef[]
  filterLabelId?: string
  onFetchItems: (args: SearchArgs, extraArgs?: any) => Promise<PageResult<T>>
  onOpenDetails?: (item: T) => void
  onExport?: (args: SearchArgs, extraArgs?: any) => Promise<void>

  /**
   * Additional custom filter to be displayed beside the standard filter box. Any event handlers should be attached
   * directly to the filter component itself, the filter will only be rendered by this component, nothing more.
   */
  extraFilter?: React.ReactNode

  /** Overrides for the search arguments persisted for the given tableId. */
  overrideArgs?: SearchArgs
  /** Additional search arguments (e.g. related to extraFilter) */
  extraArgs?: any
  /** Whether to persist search arguments for the given tableId. Defaults to true. */
  saveArgs?: boolean

  /** Mapper from field value to display value. */
  valueMapper?: ColumnValueMapper<T>

  /** Whether the table rows are selectable. Defaults to false. */
  selectable?: boolean
  /** Additional custom control to be displayed at the end of the top row. Any event handlers should be attached
   * directly to the filter component itself, the filter will only be rendered by this component, nothing more.
   */
  selectionAction?: (selectedItems: T[]) => React.ReactNode
}

export function BaseTable<T extends Idful>(p: BaseTableProp<T>) {
  const { t } = useTranslation()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const searchArgsKey = `${p.tableId}-searchArgs`
  const defaultArgs: SearchArgs = { size: 50, lastMatch: [], searchTerm: '', sortBy: p.columns[0].sortBy, ascending: true }
  const [searchArgs, setSearchArgs] = useState<SearchArgs>(() => loadSearchArgs(searchArgsKey, p.overrideArgs) ?? defaultArgs)
  const [extraArgs, setExtraArgs] = useState(p.extraArgs)
  const [result, setResult] = useState<Opt<PageResult<T>>>(undefined)
  const [initialLoadDone, setInitialLoadDone] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const [showHelp, setShowHelp] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  const [selectedItems, setSelectedItems] = useState([] as T[])
  const columns = useMemo(() => p.selectable ? [selectorColumn].concat(p.columns) : p.columns, [p.columns, p.selectable])
  const items = result?.results ?? []

  function onArgsChanged(changes: SearchArgs) {
    // Create a copy of args as it would seem that otherwise setSearchArgs has no effect (searchArgs ref doesn't change).
    let newArgs = Object.assign({}, searchArgs)
    Object.assign(newArgs, changes)
    setSearchArgs(newArgs)

    if (p.saveArgs ?? true) {
      // Save args so that the user sees the same table contents when returning from details.
      saveSearchArgs(searchArgsKey, newArgs)
    }
  }

  function onChangeSort(sortBy: string, ascending: boolean) {
    onArgsChanged({sortBy: sortBy, ascending: ascending})
  }

  function onItemSelectionChange(item: T, isSelected: boolean) {
    if (!isSelected) {
      const foundAtIndex = selectedItems.findIndex((member: T) => member.id === item.id)
      if (foundAtIndex > -1) {
        setSelectedItems(selectedItems.filter(obj => obj.id !== item.id))
      }
    } else {
      setSelectedItems(selectedItems.concat(item))
    }
  }

  function getColumnContent(column: InternalColumnDef, item: T, valueMapper: ColumnValueMapper<T>, value?: string|number) {
    if (column.isCheckbox) {
      return <Checkbox className={classes.rowSelector} onChange={(event, checked: boolean) => onItemSelectionChange(item, checked)} />
    }
    return valueMapper(column.field, value, item)
  }

  async function fetchItems<T>(args: SearchArgs, fetcher: (args: SearchArgs, extraArgs?: any) => Promise<PageResult<T>>, prevResults: Opt<T[]>, extraArgs?: any) {
    setInProgress(true)
    fetcher(args, extraArgs).then(
      response => {
        const allResultsSoFar = (prevResults ?? []).concat(response.results)
        setHasMore(response.totalResults > allResultsSoFar.length)
        setResult({
          totalResults: response.totalResults,
          // @ts-ignore "T is not compatible with T", when both T actually are the same type
          results: allResultsSoFar,
          // When calling endpoints using search_after, this will always be 0, but with SQL-based paging it is the real page number.
          page: isDefined(response.lastMatch?.length) ? response.page : 0,
          lastMatch: response.lastMatch
        })
        setInProgress(false)
      },
      error => {
        setInProgress(false)
        console.log(`Error fetching: ${JSON.stringify(error)}`)
        enqueueSnackbar(t('errFetchGeneric', {message: getErrorMessage(error)}), snacky.errorOpts)
      }
    )
  }

  function doExport() {
    const limit = 10000
    if (result && result?.totalResults <= limit) {
      p.onExport && p.onExport(searchArgs, extraArgs)
    } else {
      enqueueSnackbar(t('exportTooManyResults', {limit: limit}), snacky.infoOpts)
    }
  }

  // For initial load, explicitly set page size to override any lower values possibly persisted from earlier paging implementation.
  const fetchInitial = (searchArgs: SearchArgs, extraArgs?: any) => fetchItems({...searchArgs, size: 50, lastMatch: []}, p.onFetchItems, [], extraArgs)
  const fetchMore = (searchArgs: SearchArgs, extraArgs?: any) => fetchItems({...searchArgs, lastMatch: result?.lastMatch ?? []}, p.onFetchItems, result?.results, extraArgs)

  if (!initialLoadDone) {
    fetchInitial(searchArgs, extraArgs)
    setInitialLoadDone(true)
  }

  useEffect(() => {
    fetchInitial(searchArgs, extraArgs)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchArgs, extraArgs])

  useEffect(() => {
    setExtraArgs(p.extraArgs)
  }, [p.extraArgs])

  useAppEventListener('ExportToExcel', _type => doExport())
  useAppEventListener('RefreshResults', _type => fetchInitial(searchArgs, extraArgs))

  return (
  <TableContainer component={Paper}>
    <InfiniteScroll
      pageStart={0}
      initialLoad={false}
      // Setting page here to be compatible with SQL-backed paged endpoints. Relying here on backend to ignore page argument if also lastMatch is set.
      loadMore={(page?: number) => fetchMore({...searchArgs, page: (isDefined(page) ? page : 0)}, extraArgs)}
      hasMore={hasMore} // One extra call for loader might be done anyway due to https://github.com/danbovey/react-infinite-scroller/issues/288
      loader={<Box key='scrollerSpinner' py={2} display="flex" justifyContent="center"><CircularProgress size={30} /></Box>}
      useWindow={true}
    >
      <Table className={classes.table}>
        <colgroup>
          {columns.map((column) => (<col key={column.field} width={getColumnWidth(column)} />))}
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell colSpan={columns.length} className={classes.filterCell}>
              <div className={classes.filterCellContent}>
                <div className={classes.numberOfResults}>{isDefined(result) && t('tableNumberOfResults', {results: result?.results.length, totalResults: result?.totalResults})}</div>
                { p.extraFilter }
                { p.filterLabelId && <FilterField
                  labelId={p.filterLabelId}
                  defaultValue={searchArgs.searchTerm ?? ''}
                  onChange={value => {
                    if (searchArgs.searchTerm !== value) {
                      onArgsChanged({searchTerm: value, page: 0, lastMatch: []})
                    }
                  }}
                  onFilterInfoShown={(isShown: boolean) => setShowHelp(isShown)}
                />}
                { p.selectionAction && p.selectionAction(selectedItems) }
              </div>
              {showHelp && <FilterInstructions/>}
              <InlineLinearProgress visible={inProgress} />
            </TableCell>
          </TableRow>
          <SortableTableLabelsRow columns={columns} args={searchArgs} cellClass={classes.cell} onChangeSort={onChangeSort} />
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow key={item.id} hover className={classes.row}>
              {columns.map((column, index) =>
                <TableCell
                  key={column.field}
                  className={classes.cell}
                  onClick={p.onOpenDetails && !(p.selectable && index ===0) ? _event => selectionIgnoringClick(() => p.onOpenDetails!(item)) : undefined}
                >
                  {getColumnContent(column, item, p.valueMapper ?? mapValue, resolvePath(column.field, item))}
                </TableCell>)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </InfiniteScroll>
  </TableContainer>)
}

/** The default mapper from field value to display value. */
function mapValue(field: string, value: any) {
  if (typeof value == 'boolean') {
    // TODO or as a proper string like 'Yes' / 'No' ?
    return value === true ? '✓' : ''
  } else {
    return value
  }
}

interface SortableTableLabelsRowProp {
  columns: InternalColumnDef[]
  args: SearchArgs
  cellClass: any
  onChangeSort?: (sortBy: string, ascending: boolean) => void
}

function SortableTableLabelsRow(prop: SortableTableLabelsRowProp) {
  const { t } = useTranslation()

  return (
    <TableRow>
      {prop.columns.map((column) => (
        <TableCell key={column.field} className={prop.cellClass}>
          {column.sortBy === undefined ? t(column.headerTextId) : (
            <TableSortLabel
              active={column.sortBy === prop.args.sortBy}
              direction={prop.args.ascending ? 'asc' : 'desc'}
              onClick={() => prop.onChangeSort && prop.onChangeSort(column.sortBy!, !(prop.args.ascending ?? false))}
            >
              {t(column.headerTextId)}
            </TableSortLabel>)
          }
        </TableCell>))}
    </TableRow>
  )
}

interface FilterFieldProp {
  labelId: string
  defaultValue: string
  onChange: (value: string) => void
  onFilterInfoShown?: (isShown: boolean) => void
}

function FilterField(prop: FilterFieldProp & Partial<InfoButtonProp>) {
  const [value, setValue] = useState(prop.defaultValue)
  const [searchTerm, setSearchTerm] = useState<string>(prop.defaultValue)
  const { t } = useTranslation()
  useDebouncedCallback(searchTerm, prop.onChange, 500)
  const classes = useStyles()
  const shrink = value.length > 0; // To make the filter label disappear when there's input

  function onClear() {
    setSearchTerm('') // To prevent from debounce triggering change later on with a different value.
    prop.onChange('') // To trigger the change immediately.
    setValue('')
  }

  return <div>
    <TextField
      variant='outlined'
      value={value}
      label={ !!value ? "": t(prop.labelId) }
      id={`textField-${prop.labelId}`}
      onChange={event => {
        setValue(event.target.value)
        setSearchTerm(event.target.value)
      }}
      onKeyPress={event => {
        if (event.key === 'Enter') {
          prop.onChange(searchTerm)
        }
      }}
      InputLabelProps={{
        shrink: shrink,
        className: shrink ? undefined : classes.filterLabel
      }}
      InputProps={{
        endAdornment: <InputAdornment position='end'>
          <TIButton
            icon={<Clear/>}
            onClick={onClear}
            tooltipKey='buttonClearTooltip'
          />
        </InputAdornment>,
        startAdornment: <InputAdornment position='start' disablePointerEvents={true} disableTypography={true}>
          <Search />
        </InputAdornment>
      }}
    />
    { prop.infoTooltip ?? <InfoButton
      infoTooltip={t('tableFilterShowHelpTooltip')}
      infoTooltipHide={t('tableFilterHideHelpTooltip')}
      onInfoToggled={prop.onFilterInfoShown && prop.onFilterInfoShown}
    />}
  </div>
}

function loadSearchArgs(key: string, overrides?: SearchArgs) {
  const storedArgsStr = sessionStorage.getItem(key)

  if (storedArgsStr) {
    let args = JSON.parse(storedArgsStr) as SearchArgs
    return Object.assign(args, overrides ?? {})
  } else {
    return undefined
  }
}

function saveSearchArgs(key: string, args: SearchArgs) {
  sessionStorage.setItem(key, JSON.stringify(args))
}

export function FilterInstructions() {
  const { t } = useTranslation()
  const useStyles = makeStyles(theme => (
    {
      infobox: {
        backgroundColor: '#F5F5F5',
        borderRadius: '4px',
        padding: '0.5rem',
        marginTop: '0.5rem',
        whiteSpace: 'pre-wrap',
        fontWeight: 'normal'
      }
    }
  ))
  const classes = useStyles()

  return <Box className={classes.infobox}>
      <ul>
        <li>{t('tableFilterHelp1')}</li>
        <li>{t('tableFilterHelp2')}</li>
        <li>{t('tableFilterHelp3')}</li>
        <li>{t('tableFilterHelp4')}</li>
        <li>{t('tableFilterHelp5')}</li>
        <li>{t('tableFilterHelp6')}</li>
      </ul>
    </Box>
}