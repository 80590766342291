import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, FormControlLabel, Checkbox, Typography } from '@material-ui/core'
import { DeleteOutlined, DirectionsOutlined, HomeWorkOutlined, LocationCity } from '@material-ui/icons'
import { useSnackbar } from 'notistack'

import * as api from '../tools/API'
import { Site } from '../tools/API'
import * as snacky from './CustomSnackbarProvider'
import * as navigator from '../tools/Navigator'
import { getErrorMessage, isDefined } from '../tools/Utils'
import { DetailsCard } from './DetailsCard'
import { TIButton } from './SmallComponents'
import { LabeledItem } from './LabeledItem'
import { ConfirmDialog } from './ConfirmDialog'

export function SiteInfoCard({site, refreshSite}: {site?: Site, refreshSite?: () => Promise<void>}) {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [description, setDescription] = useState('')
  const [isDeleting, setDeleting] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  const [copyFiles, setCopyFiles] = useState(false)

  useEffect(() => { setDescription(site?.description ?? '') }, [site?.description])

  async function saveDescription(description: string) {
    try {
      await api.updateSiteDescription(site!.id, {description: description})
      site!.description = description
      setDescription(description)
    } catch (err) {
      console.log(`Failed to save description: ${JSON.stringify(err)}`)
      enqueueSnackbar(t('errSave', {message: getErrorMessage(err)}), snacky.errorOpts)
    }
  }

  async function removeFromGroup() {
    setInProgress(true)
    try {
      await api.removeSiteFromGroup(site!.siteGroup!.id, site!.id, copyFiles)
      enqueueSnackbar((t('siteGroupSiteRemovedInfo')), snacky.infoOpts)
      
      if (refreshSite) {
        await refreshSite()
      } else {
        site!.siteGroup = undefined
      }
    } catch (err) {
      console.log(`Error removing site from group: ${JSON.stringify(err)}`)
      enqueueSnackbar(t('errRemoveSiteFromGroup', {message: getErrorMessage(err)}), snacky.errorOpts)
    } finally {
      setInProgress(false)
    }
  }

  const copyFilesOption = (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={copyFiles}
            onChange={(e) => setCopyFiles(e.target.checked)}
            color="primary"
          />
        }
        label={
          <Typography variant="subtitle1" color="textSecondary">
            {t('siteInfoCopyFilesLabel')}
          </Typography>
        }
      />
      <Typography 
        variant="body2" 
        color="textSecondary" 
        style={{ marginLeft: 30, marginTop: 4 }}
      >
        {t('siteInfoCopyFilesHelp')}
      </Typography>
    </Box>
  );

  return (
    <DetailsCard cardId='siteInfo' title={t('siteInfoTitle')} icon={<LocationCity/>}>
      { site &&
      <Grid container direction='column' spacing={1}>
        <LabeledItem
          label={t('siteInfoLabelSiteGroup')}
          value={site.siteGroup?.name ?? '-'}
          action={
            isDefined(site.siteGroup) && <Box display="flex" flexDirection="row">
              <TIButton
                onClick={() => navigator.push(`/site-group/${site.siteGroup?.id}`)}
                target="_blank"
                rel="noopener"
                icon={<HomeWorkOutlined/>}
                tooltipKey="siteInfoSiteGroupTooltip"
              />
              <TIButton
                edge='end'
                disabled={inProgress}
                onClick={() => setDeleting(true)}
                target="_blank"
                rel="noopener"
                icon={<DeleteOutlined/>}
                tooltipKey="siteInfoSiteGroupRemoveTooltip"
              />
            </Box>
          }
        />
        <LabeledItem label={t('siteInfoLabelExtId')} value={site.externalId?.toString() ?? 'N/A'} />
        <LabeledItem label={t('siteInfoLabelName')} value={site.name} />
        <LabeledItem
          label={t('siteInfoLabelAddress')}
          value={formatAddress(site)}
          action={
            <TIButton
              edge='end'
              href={getMapsLink(site)}
              target="_blank"
              rel="noopener"
              icon={<DirectionsOutlined/>}
              tooltipKey="siteInfoLabelAddressTooltip"
            />
          }
        />
        <LabeledItem
          label={t('siteInfoLabelDescription')}
          value={description}
          onSave={description => saveDescription(description)}
          maxLength={3000}
        />
        { isDeleting && 
        <ConfirmDialog
          open={true}
          disabled={inProgress}
          title={t('siteInfoConfirmRemoveFromGroupTitle')}
          text={t('siteInfoConfirmRemoveFromGroupText', {name: site.siteGroup?.name})}
          customContent={copyFilesOption}
          onAction={async (yes) => {
            if (yes) {
              await removeFromGroup()
            }
            setDeleting(false)
          }}
        />
        }
      </Grid>
      }
    </DetailsCard>
  )
}

function getMapsLink(site: Site) {
  const address = formatAddress(site)
  return `https://www.google.com/maps/search/?api=1&query=${address}`
}

function formatAddress(site: Site) {
  return `${site.streetAddress ? site.streetAddress+',' : ''} ${site.postCode ?? ''} ${site.city ?? ''}`
}
