import i18n from 'i18next'
import { DateTime } from 'luxon'
import { initReactI18next } from 'react-i18next'
import { isEndOfLocalDay, isStartOfLocalDay, LocalDate, shallowEquals, truncateToDate } from './Utils'

export const defaultLanguage = 'fi'

/** Keys are language identifiers, e.g. 'fi', 'sv' or 'en' (most likely only 'fi' is provided). */
export type LocalizedValue = Record<string, string>

export function get(l?: LocalizedValue) {
  return l ? l[i18n.language] ?? l[defaultLanguage] : undefined
}

export function initI18n(language: string) {
  i18n
  .use(initReactI18next)
  .init({
    resources: strings,
    lng: language,
    interpolation: {
      escapeValue: false
    }
  })
}

export function formatAsLocalDate(dt: DateTime, weekday?: boolean) {
  const localDt = dt.setZone('local');
  if (weekday) {
    return localDt.toLocaleString({ year: 'numeric', month: 'numeric', day: 'numeric', weekday: 'long' }, {locale: i18n.language})
  }
  return localDt.toLocaleString({ dateStyle: 'short' }, {locale: i18n.language})
}

export function formatAsLocalDateTime(dt: DateTime, weekday?: boolean) {
  const localDt = dt.setZone('local');
  if (weekday) {
    // timeStyle: 'short' can only be used with dateStyle, and having explicit hour: 'numeric', minute: 'numeric' adds 'klo' in Finnish for some reason
    return `${formatAsLocalDate(dt, weekday)} ${localDt.toLocaleString({ timeStyle: 'short' }, {locale: i18n.language})}`
  }
  return localDt.toLocaleString({ dateStyle: 'short', timeStyle: 'short' }, {locale: i18n.language})
}

export function formatAsLocalTime(dt: DateTime) {
  const localDt = dt.setZone('local');
  return localDt.toLocaleString({ timeStyle: 'short' }, {locale: i18n.language})
}

/**
 * Format datetime interval as either
 * a) a plain date (with optional weekday), when the interval spans a whole single day,
 * b) a interval of plain dates, when the interval spans several whole days, or
 * c) otherwise, a interval of datetimes.
 * @param start
 * @param end
 * @param weekday
 * @returns formatted interval
 */
export function formatAsLocalDateTimeInterval(start: DateTime, end: DateTime, weekday?: boolean) {
  const startDate = truncateToDate(start.setZone('local')) as LocalDate;
  const endDate = truncateToDate(end.setZone('local')) as LocalDate;
  if (shallowEquals(startDate, endDate)) {
    if (isStartOfLocalDay(start) && isEndOfLocalDay(end)) {
      return formatAsLocalDate(start, weekday);
    }
    return `${formatAsLocalDate(start, weekday)}, ${formatAsLocalTime(start)}-${formatAsLocalTime(end)}`;
  }
  if (isStartOfLocalDay(start) && isEndOfLocalDay(end)) {
    return `${formatAsLocalDate(start)}-${formatAsLocalDate(end)}`
  }
  return `${formatAsLocalDateTime(start)} - ${formatAsLocalDateTime(end)}`;
}

export const strings = Object.freeze({
  fi: {
    translation: {
      'genericDecimalNumber': '{{value, number(minimumFractionDigits: 2)}}',
      'genericDecimalPercentage': '{{value, number(minimumFractionDigits: 2)}} %',

      'genericYes': 'Kyllä',
      'genericNo': 'Ei',

      'genericShowMore': 'Näytä enemmän',
      'genericShowLess': 'Näytä vähemmän',

      'errFetchGeneric': "Tietojen hakeminen epäonnistui: {{message}}",
      'errFetchGenericNoDetails': 'Tietojen lataaminen epäonnistui',

      'drawerTitle': "Kohdekansiot",
      'drawerMenuSites': "Kohteet",
      'drawerMenuCustomers': "Asiakkaat",
      'drawerMenuContracts': "Sopimukset",
      'drawerMenuLaadunseuranta': "Laadunseuranta",

      'sitesHeaderName': "Kohde",
      'sitesHeaderContractExtId': 'Sopimusnro',
      'sitesHeaderExtId': "Toimitusasiakas",
      'sitesHeaderCustomerName': "Asiakas",
      'sitesHeaderSiteGroupName': 'Kohderyhmä',
      'sitesHeaderStreet': "Katuosoite",
      'sitesHeaderZip': "Postinro",
      'sitesHeaderCity': "Kaupunki",
      'sitesHeaderPublished': "Näkyvyys",
      'siteLoadingMsg': 'Ladataan kohdetta...',
      'sitesSearchLabel': "Hae kohdetta",
      'sitesTableVisible': "Julkaistu",
      'sitesTableNotVisible': "Ei julkaistu",

      'siteListTabLabel': 'Kohteet',
      'siteGroupsListTabLabel': 'Kohderyhmät',
      'siteGroupsHeaderName': 'Nimi',
      'siteGroupsSearchLabel': 'Hae kohderyhmää',
      'newSiteGroupButtonLabel': 'Luo uusi kohderyhmä',

      'addSiteGroupDialogTitle' : 'Luo uusi kohderyhmä',
      'addSiteGroupDialogHelpText': 'Ohjeteksti kertomaan ryhmien käytöstä...',
      'newSiteGroupNameLabel': 'Nimi',

      'tablePageSizeLabel': 'Riviä sivulla',
      'tableNumberOfResults': 'Näytetään {{results}} yhteensä {{totalResults}} osumasta.',

      'customersHeaderExtId': "SAP-tunnus",
      'customersHeaderName': "Asiakas",
      'customersSearchLabel': "Hae asiakasta",

      'contractsSearchLabel': "Hae sopimuksia",
      'contractLinesTableActive': 'Käytössä',
      'contractLinesTableReportError': 'Raportoi virhe tiedoissa',
      'contractPrefixFilterLabel': 'Näytä sopimusnumeroista',
      'contractPrefixOptionAll': 'Kaikki',
      'contractPrefixOption1': '1-alkuiset',
      'contractPrefixOption3': '3-alkuiset',
      'contractPrefixOption5': '5-alkuiset',

      'contractLinesHeaderCustomerExtId': 'SAP-tunnus',
      'contractLinesHeaderCustomerName': 'Asiakkaan nimi',
      'contractLinesHeaderSiteName': 'Kohteen nimi',
      'contractLinesHeaderSiteAddress': 'Katuosoite',
      'contractLinesHeaderSitePostCode': 'Postinro',
      'contractLinesHeaderSiteCity': 'Kaupunki',
      'contractLinesHeaderContractExtId': 'Sopimusnumero',
      'contractLinesHeaderLineNumber': 'Rivinro',
      'contractLinesHeaderName': 'Palvelutuoteryhmä',
      'contractLinesHeaderProfitCenterExtId': 'Kustannuspaikka',
      'contractLinesHeaderResponsiblePartyName': 'Esimies',
      'contractLinesHeaderIsActive': 'Käytössä',
      'contractLinesHeaderReportError': 'Raportoi virhe',

      'contractLinesErrorDialogTitle': 'Raportoi virheestä sopimusrivin tiedoissa',
      'contractLinesErrorDialogBody': 'Sopimusrivin tiedot:',
      'contractLinesErrorDialogDescriptionLabel': 'Kuvaus virheestä',
      'contractLinesErrorDialogCustomer': 'Asiakas:',
      'contractLinesErrorDialogSite': 'Kohde:',
      'contractLinesErrorDialogProductGroup': 'Tuoteryhmä:',
      'contractLinesErrorDialogProfitCenter': 'Kustannuspaikka:',
      'contractLinesErrorDialogResponsibleParty': 'Esimies:',
      'contractLinesErrorReportSent': 'Virheilmoitus lähetetty',
      'contractLinesErrorDialogNoCustomer': '(ei asiakasta)',
      'contractLinesErrorDialogNoResponsibleParty': '(ei esimiestä)',

      'buttonYes': "Kyllä",
      'buttonNo': "Ei",
      'buttonDelete': "Poista",
      'buttonCancel': "Peruuta",
      'buttonBack': "Takaisin",
      'buttonLogout': "Kirjaudu ulos",
      'buttonAdd': "Lisää",
      'buttonSave': "Tallenna",
      'buttonClose': 'Sulje',
      'buttonSend': 'Lähetä',

      'buttonSaveTooltip': "Tallenna muutokset",
      'buttonCancelEditTooltip': "Kumoa muutokset",
      'buttonEditTooltip': "Muokkaa",
      'buttonBackTooltip': "Palaa takaisin",
      'buttonMenuTooltip': "Näytä valikko",
      'buttonAddFileTooltip': "Lisää tiedosto",
      'buttonClearTooltip': "Tyhjennä",
      'buttonShowMarkdownInstructionsToolTip': "Näytä muotoiluohje",
      'buttonHideMarkdownInstructionsToolTip': 'Piilota muotoiluohje',

      'detailsButtonExpandAll': "Avaa kaikki kortit",
      'detailsButtonCollapseAll': "Sulje kaikki kortit",
      'exportToExcelButton': 'Vie tiedot Exceliin',
      'exportTooManyResults': 'Exceliin voidaan viedä kerralla korkeintaan {{limit}} riviä. Rajaa hakutuloksia.',

      'tableNoResults': "Ei hakutuloksia",
      'tableFilterShowHelpTooltip': 'Näytä haun ohje',
      'tableFilterHideHelpTooltip': 'Piilota haun ohje',
      'tableFilterHelp1': 'Voit syöttää useita hakusanoja välilyönnillä erotettuna. Isoilla ja pienillä kirjaimilla ei ole eroa.',
      'tableFilterHelp2': 'Jokainen hakusana kohdistuu kaikkiin näkyviin teksti- ja numeromuotoisiin kenttiin.',
      'tableFilterHelp3': 'Väliviivalliset sanat käyttäytyvät kuin kaksi eri sanaa, esim. "savo" täsmää sanaan "Etelä-Savon".',
      'tableFilterHelp4': 'Tekstimuotoisissa kentissä haku kohdistuu minkä tahansa kentässä olevan sanan alkuosaan, esim. "kristi" täsmää sanaan "Kristiinankaupunki". Postinumeroa käsitellään myös tällä tavoin.',
      'tableFilterHelp5': 'Numeromuotoisissa tunnistekentissä hakusanan täytyy täsmätä kokonaan, esim. "5390" ei löydä tunnistetta "5390908".',
      'tableFilterHelp6': 'Kyllä/ei -muotoisista kentistä (esim. Näkyvyys) ei voi hakea, mutta voit saada jommankumman vaihtoehdon mukaiset rivit esille järjestämällä rivit ko. kentän mukaan.',

      'comboboxAddOption': "Lisää",
      'comboboxNoMatchingOptions': "Ei vaihtoehtoja",

      'batchOperationDialogButtonRetry': 'Yritä epäonnistuneiden lähetystä uudelleen',

      'errFetchSiteDetails': "Kohteen tietojen lataus epäonnistui: {{message}}",
      'errFetchSiteGroup': "Kohderyhmän tietojen lataus epäonnistui: {{message}}",
      'errAddSiteToGroup': "Kohteen lisääminen ryhmään epäonnistui: {{message}}",
      'errRemoveSiteFromGroup': "Kohteen poistaminen ryhmästä epäonnistui: {{message}}",
      'errSearchParties': "Henkilöhaku epäonnistui: {{message}}",
      'errFetchServiceDescription': "Palvelukuvauksen tietojen lataus epäonnistui: {{message}}",
      'errFetchCalendarEvent': "Kalenteritapahtuman tietojen lataus epäonnistui: {{message}}",
      'errSave': "Tallennus epäonnistui: {{message}}",
      'errSaveDueToScanFailure': "Tiedosto ei läpäissyt tietoturvatarkistusta",
      'errUnknownError': 'Tuntematon virhe',
      'errNotFound': 'Ei löydy: {{object}}',
      'errServiceNotAvailable': 'Palvelin ei vastaa',
      'errAccessDenied': 'Pääsy estetty',
      'errSendContractLineErrorReport': 'Virheilmoituksen lähetys epäonnistui: {{message}}',
      'errExport': 'Tietojen vienti epäonnistui: {{message}}',
      'errFetchPriceListDetails': "Hinnaston tietojen lataus epäonnistui: {{message}}",

      'errSignIn': "Kirjautuminen epäonnistui: {{message}}",

      'errUnauthorized': 'Ei käyttöoikeutta',
      'unauthorizedRedirectionLabel': 'Palataan automaattisesti takaisin kirjautumissivulle 5 sekunnin kuluttua...',

      'noPartySearchResults': "Syötä osa nimestä etsiäksesi henkilöitä",

      'siteInfoTitle': "Kohdetiedot",
      'siteInfoLabelSiteGroup': "Ryhmä",
      'siteInfoSiteGroupTooltip': "Avaa kohteen ryhmä",
      'siteInfoSiteGroupRemoveTooltip': "Poista kohde ryhmästä",
      'siteInfoLabelExtId': "SAP Toimitusasiakas ID",
      'siteInfoLabelName': "Nimi",
      'siteInfoLabelAddress': "Osoite",
      'siteInfoLabelAddressTooltip': "Näytä Google Mapsissa",
      'siteInfoLabelDescription': "Kuvaus",
      'siteStatusTitle': "Sisällön tila",
      'siteStatusLabelPublished': "Julkaistu",
      'siteStatusLabelNotPublished': "Julkaisematon",
      'siteInfoConfirmRemoveFromGroupTitle': "Poista kohde ryhmästä",
      'siteInfoConfirmRemoveFromGroupText': "Haluatko poistaa kohteen ryhmästä '{{name}}'?",
      'siteInfoConfirmRemoveMultipleFromGroupTitle': "Poista kohteet ryhmästä",
      'siteInfoConfirmRemoveMultipleFromGroupText': "Haluatko poistaa {{count}} kohdetta ryhmästä '{{name}}'?",
      'siteInfoCopyFilesLabel': "Kopioi ryhmän tiedostot kohteeseen",
      'siteInfoCopyFilesLabelMultiple': "Kopioi ryhmän tiedostot kohteisiin",
      'siteInfoCopyFilesHelp': "Kun valittu, kohteeseen kopioidaan ryhmän tiedostot niillä tiedoilla ja sisällöllä, jotka niillä tällä hetkellä on. Ryhmän tiedostoihin myöhemmin tehtävät muutokset eivät vaikuta kohteeseen kopioituihin tiedostoihin.",
      'siteInfoCopyFilesHelpMultiple': "Kun valittu, kohteisiin kopioidaan ryhmän tiedostot niillä tiedoilla ja sisällöllä, jotka niillä tällä hetkellä on. Ryhmän tiedostoihin myöhemmin tehtävät muutokset eivät vaikuta kohteisiin kopioituihin tiedostoihin.",
      'siteGroupSiteRemovedInfo': "Kohde poistettu ryhmästä",

      'siteAttachmentsTitle': "Liitteet",

      'customerAttachmentsTitle': "Liitteet",
      'customerTeamTitle': "Asiakkuustiimi",
      'customerSitesCardTitle': "Kohteet",
      'customerSitesTableTitle': "Kohteet: {{name}}",
      'customerPartiesCardTitle': "Omat työntekijät",
      'customerLoadingMsg': 'Ladataan asiakasta...',

      'filesCardSubtitle_zero': "Ei liitteitä",
      'filesCardSubtitle_one': "{{count}} liite",
      'filesCardSubtitle_other': "{{count}} liitettä",

      'siteCalendarTitle': "Kalenteri",
      'siteCalendarSubtitle_zero': "Ei tapahtumia",
      'siteCalendarSubtitle_one': "{{count}} tapahtuma",
      'siteCalendarSubtitle_other': "{{count}} tapahtumaa",
      'siteCalendarSubtitleLoading': 'Ladataan tapahtumia...',
      'siteCalendarPast': "Menneet",
      'siteCalendarToday': "Tänään",
      'siteCalendarTomorrow': "Huomenna",
      'siteCalendarLater': "Myöhemmin",
      'confirmCalendarEventRemovalTitle': "Poista kalenteritapahtuma",
      'confirmCalendarEventRemovalText': "Haluatko poistaa kalenteritapahtuman '{{name}}'?",
      'errRemovingCalendarEvent': "Kalenteritapahtuman poisto epäonnistui: {{message}}",

      // caev = CalendarEvent
      'caevDeleteTooltip': "Poista kalenteritapahtuma",
      'caevEditTooltip': "Muokkaa kalenteritapahtumaa",
      'caevAddTooltip': "Luo kalenteritapahtuma",
      'caevConfirmRemoveTitle': "Poista kalenteritapahtuma",
      'caevConfirmRemoveText': "Haluatko poistaa kalenteritapahtuman '{{name}}'?",
      'caevDialogTitleEdit': "Muokkaa kalenteritapahtumaa",
      'caevDialogTitleAdd': "Luo kalenteritapahtuma",
      'caevLabelSubject': "Otsikko",
      'caevLabelLocation': "Tapahtuman sijainti",
      'caevLabelDescription': "Kuvaus",
      'caevLabelDate': "Päivä",
      'caevLabelStart': "Alku",
      'caevLabelEnd': "Loppu",
      'caevLabelIsAllDayEvent': "Koko päivä",

      'siteEvaluationEventTitle': "Laadunseurantakierros",
      'siteEvaluationEventSubtitle_zero': "Ei tapahtumia",
      'siteEvaluationEventSubtitle_one': "{{count}} tapahtuma",
      'siteEvaluationEventSubtitle_other': "{{count}} tapahtumaa",
      'siteEvaluationEventLate': "Myöhässä",
      'siteEvaluationEventCurrent': "Käynnissä",
      'siteEvaluationEventFuture': "Tulevat",
      'siteEvaluationEventTooltip': "Näytä aktivointi Laadunseurannassa",
      'siteEvaluationEventLoading': 'Ladataan tietoja...',

      'siteCustomerTitle': "Asiakkuus",
      'siteCustomerLabelName': "Nimi",
      'siteCustomerLabelExtId': "SAP-tunniste",
      'siteCustomerLinkTooltip': "Avaa asiakkaan tiedot",

      'siteServiceDescriptionsTitle': "Palvelukuvaukset",
      'siteServiceDescriptionsSubtitle_zero': "Ei palvelukuvauksia",
      'siteServiceDescriptionsSubtitle_one': "{{count}} palvelukuvaus",
      'siteServiceDescriptionsSubtitle_other': "{{count}} palvelukuvausta",
      'siteServiceDescriptionLoading': "Ladataan palvelukuvauksia...",

      // sdc=Service Description Card
      'sdcTitle': "Palvelukuvaus",
      'sdcDialogTitleEdit': "Muokkaa palvelukuvausta",
      'sdcDialogTitleAdd': "Luo palvelukuvaus",
      'sdcLabelName': "Nimi",
      'sdcLabelInContract': "Sopimukseen kuuluvia töitä",
      'sdcLabelNotInContract': "Erillisveloitettavia töitä ja tarvikkeita",
      'sdcLabelAdditionalInformation': "Lisätiedot",
      'sdcLabelServiceCode': "Palvelukoodi",
      'sdcLabelAttachments': "Dokumentit",
      'sdcAddTooltip': "Luo palvelukuvaus",
      'sdcEditTooltip': "Muokkaa palvelukuvausta",
      'sdcDeleteTooltip': "Poista palvelukuvaus",

      'sdcDeleteConfirmTitle': "Poista palvelukuvaus",
      'sdcDeleteConfirmText': "Haluatko poistaa palvelukuvauksen {{name}}?",

      'noMatchingProductGroups': "Palvelukoodia ei löytynyt",

      'siteServiceTeamTitle': "Palvelutiimi",

      'teamCardAddTooltip': "Lisää henkilö tiimiin",
      'teamMemberDeleteTooltip': "Poista henkilö tiimistä",
      'teamMemberEditTooltip': "Muokkaa roolia",
      'teamMemberSitesTooltip': "Henkilön kohteet",
      'teamMemberLockedTooltip': "Automaattisesti lisättyä jäsentä ei voi muokata",
      'teamDialogTitleAdd': "Lisää henkilö tiimiin",
      'teamCardLoading': "Ladataan tietoja...",
      'teamSubtitle_zero': "Ei henkilöitä",
      'teamSubtitle_one': "{{count}} henkilö",
      'teamSubtitle_other': "{{count}} henkilöä",
      'teamGroupServiceTeamSpecialSubtitle_zero': "Ei aktiivisia",
      'teamGroupServiceTeamSpecialSubtitle_one': "{{count}} aktiivinen",
      'teamGroupServiceTeamSpecialSubtitle_other': "{{count}} aktiivisia",
      'teamGroupServiceTeamActiveSubtitle_zero': "Ei passiivisia",
      'teamGroupServiceTeamActiveSubtitle_one': "{{count}} passiivinen",
      'teamGroupServiceTeamActiveSubtitle_other': "{{count}} passiivista",
      'teamGroupServiceTeamInactiveSubtitle_zero': "Ei poistuneita",
      'teamGroupServiceTeamInactiveSubtitle_one': "{{count}} poistunut",
      'teamGroupServiceTeamInactiveSubtitle_other': "{{count}} poistunutta",
      'teamGroupServiceTeamResponsibleSubtitle_zero': "Ei vastuuhenkilöitä",
      'teamGroupServiceTeamResponsibleSubtitle_one': "{{count}} vastuuhenkilö",
      'teamGroupServiceTeamResponsibleSubtitle_other': "{{count}} vastuuhenkilöä",
      'teamDialogTitleEdit': "Muokkaa roolia",
      'teamDialogLabelRole': "Rooli",
      'teamDialogLabelParty': "Henkilö",
      'teamDialogRoleHelper': "Korvaa oletuksena näytettävä titteli lisäämällä henkilölle rooli (vapaaehtoinen)",
      'errAddTeamMember': "Henkilön lisäys epäonnistui: {{message}}",
      'errDelTeamMember': "Henkilön poisto epäonnistui: {{message}}",
      'confirmTeamMemberRemoveTitle': "Poista henkilö tiimistä",
      'confirmTeamMemberRemoveText': "Haluatko poistaa henkilön {{name}} tiimistä?",

      'partySitesTitle': "Kohteet: {{name}}",

      'fileEditTooltip': "Muokkaa tiedostoa",
      'fileDeleteTooltip': "Poista tiedosto",
      'fileLockedTooltip': "Ryhmästä tulevaa tiedostoa ei voi muokata kohteella",
      'fileAddTooltip': "Lisää tiedostoja",
      'fileDownloadTooltip': "Lataa tiedosto",
      'fileUncategorised': "Ei kategoriaa",

      'fileDialogTitleAdd': "Lisää tiedostoja",
      'fileDialogTitleEdit': "Muokkaa tiedostoa",
      'fileDialogButtonReplaceFile': "Korvaa liitetty tiedosto",
      'fileDialogLabelDescription': "Kuvaus",
      'fileDialogLabelCategory': "Kategoria",
      'fileDialogInfoTextTip': "Suosi mobiilikäyttöön soveltuvia liitetiedostoja: pieni koko ja helppo lukea pieneltä näytöltä.\n\nTiedoston enimmäiskoko on 140MB\n\nKuvat png tai jpg -muodossa\n\nVideot mp4 -muodossa\n\nMuut dokumentit mieluiten pdf-muodossa\n\nMuiden dokumenttien osalta, huomaa että puhelimessa pitää olla ohjelma, joka osaa avata tiedostoja.",
      'fileDialogLabelLink': "Linkki",
      'fileDialogGenerateLink': "Luo liitteen linkki",
      'fileDialogLinkCopiedInfo': 'Linkki kopioitu leikepöydälle',
      'fileDialogInfoTextSize': "Tiedosto saa olla korkeintaan 140 MB.",
      'confirmFileRemoveTitle': "Poista tiedosto",
      'confirmFileRemoveText': "Haluatko poistaa tiedoston {{name}}?",

      'filePickerButtonLabel': "Selaa...",

      'fileUploadBatchDialogTitle': 'Tiedostojen lähetys',
      'fileUploadBatchDialogProgress': 'Lähetetään tiedostoja',
      'fileUploadBatchFailed': 'Joidenkin tiedostojen lähetys epäonnistui.',
      'fileUploadBatchSuccess': 'Tiedostojen lähetys valmis.',

      'siteInstructionsTitle': "Työohjeet",
      'siteInstructionsSubtitle_zero': "Ei työohjeita",
      'siteInstructionsSubtitle_one': "{{count}} työohje",
      'siteInstructionsSubtitle_other': "{{count}} työohjetta",

      'siteFormTitle': "Päivystystiedot",

      'siteOtherContactsTitle': "Muut yhteystiedot",

      'siteCustomerTeamTitle': "Asiakkuustiimi",

      'siteTitle': "Muut yhteystiedot",

      'siteContractLinesCardTitle': "Sopimusrivit",
      'siteContractLinesTableTitleWithName': "Sopimusrivit: {{name}}",
      'siteContractLinesTableTitle': "Sopimusrivit",

      'siteGroupTitle': "Kohderyhmä",
      'siteGroupLabelName': "Nimi",
      'siteGroupLabelDescription': "Kuvaus",
      'siteGroupAttachmentsTitle': "Liitteet",
      'siteGroupSitesCardTitle': "Kohteet",
      'siteGroupSitesTitle': "Ryhmän kohteet",
      'siteGroupSitesTitleWithName': "Ryhmän kohteet: {{name}}",
      'siteGroupSiteRemoveButtonLabel': "Poista valitut ryhmästä",
      'siteGroupSitesListTabLabel': "Ryhmän kohteet",
      'siteGroupSiteAdditionTabLabel': "Kohteen lisääminen",
      'siteGroupSiteAdditionButtonLabel': "Lisää valitut ryhmään",
      'siteGroupSitesAddedInfo': "Kohteet lisätty ryhmään",
      'siteGroupSitesRemovedInfo': "Kohteet poistettu ryhmästä",

      'sitePriceListLoading': 'Ladataan sopimusnumeroita...',
      'sitePriceListTitle': 'Hinnastot',
      'siteHasNoContracts': 'Kohteella ei ole sopimuksia',
      'sitePriceListForContractLabel': 'Sopimuksen {{contractId}} hinnasto',
      'sitePriceListDetailsTabLabel': 'Tiedot',
      'sitePriceListTabLabel': 'Hinnasto',
      'sitePriceListViewTitle': "Hinnasto {{priceListName}}",
      'sitePriceListViewTitleWithName': "Hinnasto {{priceListName}} {{customerName}} {{siteAddress}}",

      'priceListDetailsItem_affiliationType': 'Sidonnaisuus',
      'priceListDetailsItem_customerGroup': 'Asiakasryhmä',
      'priceListDetailsItem_priceListName': 'Hinnasto',
      'priceListDetailsItem_reference': 'Viite',
      'priceListDetailsItem_hasRoundedHoursDenied': 'Tuntien pyöristys kielletty',
      'priceListDetailsItem_labourMargin': 'Työkate',
      'priceListDetailsItem_travelMargin': 'Matkakate',
      'priceListDetailsItem_materialsMargin': 'Tarvikekate',
      'priceListDetailsItem_defaultMargin': 'Vakiokate',
      'priceListDetailsItem_multiplier': 'Kerroinprosentti',
      'priceListDetailsItem_vatCode': 'Alv-koodi',
      'priceListDetailsItem_hasExtraHoursDenied': 'Lisätunnit kielletty',
      'priceListDetailsItem_isActive': 'Aktiivinen',
      'priceListDataNotAvailable': 'Ei hinnaston tietoja saatavilla',

      'priceListVatCode_UNSPECIFIED': 'Ei määritelty',
      'priceListVatCode_VAT24': 'Alv 24%',
      'priceListVatCode_VAT14': 'Alv 14%',
      'priceListVatCode_VAT10': 'Alv 10%',
      'priceListVatCode_NO_VAT': 'Arvonlisäveroton myynti',
      'priceListVatCode_INVERTED_VAT': 'Käänteinen alv-velvollisuus',

      'priceListSearchLabel': 'Hae sopimusrivejä',
      'priceListLineHeaderProductName': 'Tuotenimi',
      'priceListLineHeaderProductNumber': 'Tuotenumero',
      'priceListLineHeaderUnit': 'Yksikkö',
      'priceListLineHeaderUnitPrice': 'Yksikköhinta',
      'priceListLineHeaderValidFrom': 'Voim.olo alkaa',
      'priceListLineHeaderValidTo': 'Voim.olo loppuu',

      'linesHeaderContractExtId': "Sopimusnumero",
      'linesHeaderLineNumber': "Rivinumero",
      'linesHeaderName': "Nimi",
      'linesHeaderServiceCode': "Tuoteryhmä",
      'linesHeaderActive': "Käytössä",

      'logCardTitle': "Lokitiedot",
      'logTableTitle': "Lokitiedot: {{name}}",
      'logTableHeaderName': "Nimi",
      'logTableHeaderAccessed': "Ajankohta",
      'logTableHeaderLastname': "Sukunimi",
      'logTableHeaderFirstname': "Etunimi",
      'logTableHeaderEmail': "Sähköposti",
      'logTableHeaderJobTitle': "Tehtävänimike",
      'logTableHeaderExternalId': "Henkilönro.",
      'logTableHeaderTargetType': "Käsite",
      'logTableHeaderTargetSubType': "Liittyvä käsite",
      'logTableHeaderActionType': "Toimenpide",

      'logTableFilterLabel': "Hae nimellä",

      'logTableTargetTypeSITE': "Kohde",
      'logTableTargetTypeSITE_TEAM': "Tiimi",
      'logTableTargetTypeCALENDAR_EVENT': "Kalenteritapahtuma",
      'logTableTargetTypeSERVICE_DESCRIPTION': "Palvelukuvaus",
      'logTableTargetTypeFILE_ATTACHMENT': "Liitetiedosto",
      'logTableTargetTypeCONTRACT_PRICE_LIST': "Hinnasto",

      'logTableTargetSubTypeEXTERNAL_PARTIES': "Ulkoiset käyttäjät",
      'logTableTargetSubTypeISS_PARTIES': "ISS:n käyttäjät",
      'logTableTargetSubTypeTEAM_MEMBER': "Tiimin jäsen",

      'logTableActionTypeREAD': "Luku",
      'logTableActionTypeREAD_MANY': "Listaus",
      'logTableActionTypeCREATE': "Luonti",
      'logTableActionTypeUPDATE': "Muokkaus",
      'logTableActionTypeDELETE': "Poisto",

      'tableJumpFirstTooltip': "Ensimmäinen sivu",
      'tableNextPageTooltip': "Seuraava sivu",
      'tablePrevPageTooltip': "Edellinen sivu",
      'tableJumpLastTooltip': "Viimeinen sivu",

      'markDownInstructionsHeading': 'Otsikointi',
      'markDownInstructionsHeadingLevel': 'Otsikkotaso',
      'markDownInstructionsList': 'Lista',
      'markDownInstructionsListItem': 'Luettelokohde',
      'markDownInstructionsNumberedList': 'Numeroitu lista',
      'markDownInstructionsMultilevelList': 'Monitasoinen lista',
      'markDownInstructionsFirstItem': 'Ensimmäinen',
      'markDownInstructionsSecondItem': 'Toinen',
      'markDownInstructionsSubItemNeedsFourSpaces': 'Alakohdan alussa sisennys 4 väliä',
      'markDownInstructionsBold': 'Lihavointi',
      'markDownInstructionsItalics': 'Kursivointi',
      'markDownInstructionsQuote': 'Lainaus',

      'signInCardTitle': 'Kirjaudu Kohdekansioihin',
      'signInDividerLabel': 'Tai ulkoisena käyttäjänä',
      'signInUsernameLabel': 'Käyttäjätunnus',
      'signInPasswordLabel': 'Salasana',
      'signInButtonLabel': 'Kirjaudu',
      'signInWithAadButtonLabel': 'Kirjaudu ISS-tilillä',
      'signInErrInvalidCredentials': 'Väärä käyttäjätunnus tai salasana',
      'signInErrCredentialsExpired': 'Tunnus tai salasana vanhentunut',
      'signInErrAccountExpired': 'Kirjautuminen ei onnistunut: tili on vanhentunut',
      'signInErrAccountDisabled': 'Kirjautuminen ei onnistunut: tili on poissa käytöstä',
      'signInErrAccountLocked': 'Kirjautuminen ei onnistunut: tili on lukittu - yritä hetken kuluttua uudelleen',
      'signInErrInvalidUserInfo': 'Kirjautuminen ei valitettavasti onnistunut. Asiasta on lähetetty ilmoitus eteenpäin.',
      'signInErrInvalidRole': 'Kirjautuminen ei onnistunut: ei oikeutta kirjautua',
      'signInErrGeneric': 'Kirjautuminen ei onnistunut: tuntematon virhe',
      'signInErrUnknownAadUser': 'Tuntematon käyttäjä',
      'signInErrAadInteractionInProgress': 'Sisään- tai uloskirjautumisprosessi on kesken, sulje avoinna oleva dialogi',
      'signInErrUserCancelled': 'Käyttäjä keskeytti kirjautumisen',
    }
  },
  // NOTE en/sv texts are incomplete because forgot initially that only fi is required. But decided to leave the ones that had already been typed in.
  en: {
    translation: {
      'errFetchGeneric': "Error loading details: {{message}}",

      'drawerTitle': "Kohdekansio",
      'drawerMenuSites': "Sites",
      'drawerMenuCustomers': "Customers",

      'sitesHeaderName': "Site",
      'sitesHeaderExtId': "SAP ID",
      'sitesHeaderCustomerName': "Customer",
      'sitesHeaderStreet': "Street address",
      'sitesHeaderZip': "Zip code",
      'sitesHeaderCity': "City",
      'sitesSearchLabel': "Find site",

      'tablePageSizeLabel': 'Rows per page',

      'customersHeaderExtId': "SAP ID",
      'customersHeaderName': "Customer",
      'customersSearchLabel': "Find customer",

      'buttonYes': "Yes",
      'buttonNo': "No",
      'buttonDelete': "Remove",
      'buttonCancel': "Cancel",
      'buttonBack': "Back",
      'buttonLogout': "Log out",

      'tableNoResults': "No results"
    }
  },
  sv: {
    translation: {
      'errFetchGeneric': "Fel vid inläsning av detaljer: {{message}}",

      'drawerTitle': "Kohdekansio",
      'drawerMenuSites': "Platser",
      'drawerMenuCustomers': "Kunder",

      'sitesHeaderName': "Plats",
      'sitesHeaderExtId': "SAP ID",
      'sitesHeaderCustomerName': "Kund",
      'sitesHeaderStreet': "Gatuadress",
      'sitesHeaderZip': "Postnummer",
      'sitesHeaderCity': "Stad",
      'sitesSearchLabel': "Hitta plats",

      'tablePageSizeLabel': 'Rader per sida',

      'customersHeaderExtId': "SAP ID",
      'customersHeaderName': "Kund",
      'customersSearchLabel': "Hitta kund",

      'buttonYes': "Ja",
      'buttonNo': "Nej",
      'buttonDelete': "Radera",
      'buttonCancel': "Annulera",
      'buttonBack': "Tillbaka",
      'buttonLogout': "Logga ut",

      'tableNoResults': "Inga resultat"
    }
  }
})
